<script setup>
import { computed, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n';
import wgButton from '@/components/common/wgButton.vue'
import CountDown from './CountDown.vue'

const { t } = useI18n();

const props = defineProps({
    turntableInfo: {
        type: Object
    }
})

const emits = defineEmits(['withdrawClick'])

const progressNum = computed(() => {
    const receiveAmount = Number(props?.turntableInfo?.spin_reward);
    const availableAmount = Number(props?.turntableInfo?.available_amount);

    if (isNaN(receiveAmount) || isNaN(availableAmount) || availableAmount === 0) {
        return 0;  // 如果有 NaN 或者除以零的情况，返回 0
    }
    console.log(receiveAmount, availableAmount)
    const result = receiveAmount / availableAmount;
    return result.toFixed(2) * 100;
});

const withdrawHandle = () => {
    emits('withdrawClick')
}

</script>

<template>
    <div class="px-[1rem] py-[0.5rem] bg-[#222942] rounded-md">
        <div class="flex justify-between text-[1rem] text-[#fff]">
            <div class="font-bold"> {{ t('scheduleInfo_007') }} </div>
            <div v-if="turntableInfo.expired && turntableInfo.user_spin_end_time" class="flex">
                <span>{{t('scheduleInfo_022')}}: </span>
                <CountDown :targetTimestamp="turntableInfo.user_spin_end_time"></CountDown>
            </div>
            <div v-else>{{ t('scheduleInfo_008') }}</div>
        </div>
        <el-progress :percentage="progressNum" :show-text="false" color="#FFB355" class="mt-[0.22rem]" />
        <div class="flex justify-between items-center mt-[0.5rem]">
            <div class="text-center text-[#fff] text-[0.75rem] ">
                {{ t('scheduleInfo_001') }} {{ props?.turntableInfo?.available_gap }}
                {{ t('scheduleInfo_002') }} {{ props?.turntableInfo?.available_amount }}
            </div>
            <div>
                <span class="text-[#fff]" v-if="turntableInfo.receive_reward">
                    {{ $t('scheduleInfo_023') }}
                </span>
                <wgButton v-else @click="withdrawHandle" class="py-[0.69rem] text-[#fff] !bg-[#3E4B79]">{{ t('Withdraw') }}
                </wgButton>
            </div>
        </div>

    </div>
</template>

<style scoped>
::v-deep .el-progress-bar__outer {
    background-color: #121829;
}
</style>