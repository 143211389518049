<script setup>
import { ref, defineProps, defineEmits, onBeforeUpdate, onBeforeMount, onMounted, nextTick, getCurrentInstance, computed } from 'vue';
import wgDialog from '@/components/common/wgDialog.vue';
import md5 from 'crypto-js/md5';
import serverConfig from '@/assets/network/serverConfig';
import { useI18n } from 'vue-i18n';
import { useAppStore, useLoginStore } from '@/store/store';
import { toast } from 'vue3-toastify';
import { setCookieAccessToken, setCookieEmail, setCookieUserId, setCookieUsername, setCookiesGmailLogin, getWebsiteName } from '@/assets/tools/CommonTool';
import { loginService, loginWithTelegramService, googleAuth, registerForceInputInviteCodeService, registerService, googleTokenService, resetEmailPasswordSendCodeService, resetEmailPasswordService, getTGBotIdService } from '@/assets/network/service/user';
import { fpCompleteRegistrationEvent, fpInit } from '@/assets/tools/FacebookPixelEvent';
import { useReCaptcha } from 'vue-recaptcha-v3';
import VerifyEmail from '@/components/login/VerifyEmail.vue';
import loadingAniJson from '@/assets/animation/export_sclogo_wingame.json';
import { Vue3Lottie } from 'vue3-lottie';
import { useRouter } from 'vue-router';
import { gaLoginEvent, gaSignUpEvent } from '@/assets/tools/GoogleAnalyticsEvent';
import { sendAdjustEventLogin, sendAdjustEventRegister } from '@/assets/tools/adjustConfig';

const router = useRouter();
const appStore = useAppStore();
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
const { t } = useI18n();
const loginStore = useLoginStore();

const props = defineProps({
  show: Boolean
});
const emit = defineEmits(['close', 'loginAfter']);
const instance = getCurrentInstance();

const website = computed(() => {
  const temp = getWebsiteName()
  return temp
});

const isMobile = ref(false);
const isLoading = ref(false);
const refVerifyEmail = ref();
const isArrowUp = ref(false);
const focusStatus = ref({});
const errorStatus = ref({});
const showPassword = ref(false);
const resetPassword = ref(false);
const setPassword = ref(false);
const inputConfig = ref({
  login_email: '',
  login_password: '',
  sign_up_email: '',
  sign_up_password: ''
});
const resetInputConfig = ref({
  email: '',
  password: '',
  password2: '',
  code: ''
});
const botId = ref(serverConfig.botId);
const sendCodeText = ref('');
const sendCodeTimer = ref(null);
const googleToken = ref(0);
const isShowGoogleLogin = ref(false);

onBeforeMount(() => {
  getGameConfig();
});

onBeforeUpdate(() => {
  init();
});

onMounted(async () => {
  isMobile.value = instance.proxy.$isMobile();
  const botIdData = await getTGBotIdService();
  if (botIdData.data.data && botIdData.data.data.length > 0) {
    botId.value = botIdData.data.data;
  }
});

nextTick(() => {
  setTimeout(() => {
    isShowGoogleLogin.value = true;
  }, 1000);
});

const init = () => {
  inputConfig.value = {
    login_email: '',
    login_password: '',
    sign_up_email: '',
    sign_up_password: '',
    sign_up_referral_code: appStore.fpReferral,
    sign_up_email_code: ''
  };
  if (inputConfig.value.sign_up_referral_code) {
    isArrowUp.value = true;
  } else {
    isArrowUp.value = false;
  }
  focusStatus.value = {};
  errorStatus.value = {};
};
const initResetPassword = () => {
  resetInputConfig.value = {
    email: '',
    password: '',
    password2: '',
    code: ''
  };
  sendCodeText.value = t('send_code');
};
const jump = (path) => {
  if (isMobile.value) {
    router.push(path);
  } else {
    window.open(path, '_blank');
  }
};
const getGoogleToken = async () => {
  try {
    await recaptchaLoaded();
    const _token = await executeRecaptcha('login');
    const res = await googleTokenService({ token: _token });
    console.log('googleToken', res.data?.data);
    googleToken.value = res.data?.data;
    // googleToken.value = 0.8;
  } catch (e) {
    // googleToken.value = 0.8;
    toast(e, { autoClose: 3000, type: 'error' });
  }
};
let gameConfig = {};
const getGameConfig = () => {
  registerForceInputInviteCodeService().then((res) => {
    if (res.data.code == 200) {
      gameConfig = res.data.data;
    } else {
      gameConfig = {};
    }
    console.log('gameConfig', gameConfig);
  });
};
const closeMyself = () => {
  clearInterval(sendCodeTimer.value);
  sendCodeTimer.value = null;
  emit('close');
};
const loginFinish = () => {
  refVerifyEmail.value.closeSelf();
  closeMyself();
  emit('loginAfter');
};
const clickTab = (_type) => {
  loginStore.updateActType(_type);
};
const inputFocus = (_code) => {
  focusStatus.value[_code] = true;
};
const inputBlur = (_code) => {
  focusStatus.value[_code] = false;
};
const inputError = (_code, _bool, _tip) => {
  errorStatus.value[_code] = {};
  errorStatus.value[_code].show = _bool;
  errorStatus.value[_code].tip = _tip || '';
};
const setInputDivClass = (_code) => {
  let className = '';
  if (focusStatus.value[_code]) {
    className += ' focus';
  }
  if (errorStatus.value[_code] && errorStatus.value[_code].show) {
    className += ' error';
  }
  return className;
};

const setShowPassword = () => {
  showPassword.value = !showPassword.value;
};

const clickForgetPassword = () => {
  initResetPassword();
  resetPassword.value = true;
};
const clickResetPasswordBtn = () => {
  if (!resetInputConfig.value.email) {
    toast(t('email_is_required'), { autoClose: 2000, type: 'error' });
    return;
  } else if (!resetInputConfig.value.email.includes('@')) {
    toast(t('email_format___incorrect'), { autoClose: 2000, type: 'error' });
    return;
  }
  resetPassword.value = false;
  setPassword.value = true;
};
const startCountdown = (duration, callback) => {
  let time = duration;
  sendCodeTimer.value = setInterval(() => {
    time--;
    sendCodeText.value = `${time}s`;
    if (time < 0) {
      clearInterval(sendCodeTimer.value);
      sendCodeTimer.value = null;
      callback();
    }
  }, 1000);
};
const clickSendCode = () => {
  if (sendCodeTimer.value != null) {
    return;
  }
  const par = {
    email: resetInputConfig.value.email
  };
  isLoading.value = true;
  resetEmailPasswordSendCodeService(par)
    .then(() => {
      isLoading.value = false;
      toast(t('sent_successfully'));
      startCountdown(60, () => {
        sendCodeText.value = t('send_code');
      });
    })
    .catch((error) => {
      isLoading.value = false;
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    });
};

const clickSetPasswordBtn = () => {
  if (resetInputConfig.value.password != resetInputConfig.value.password2) {
    toast(t('the_password___inconsistent'), { autoClose: 2000, type: 'error' });
    return;
  }
  const isVerified = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,12}$/.test(resetInputConfig.value.password);
  if (!isVerified) {
    toast(t('the_new___invalid'), { autoClose: 2000, type: 'error' });
    return;
  }
  if (!resetInputConfig.value.code) {
    toast(t('please_input___code'), { autoClose: 2000, type: 'error' });
    return;
  }
  const saltPWD = md5(resetInputConfig.value.password + '{PWD_Salt_CC_Poker}').toString();
  const par = {
    email: resetInputConfig.value.email,
    password1: saltPWD,
    password2: saltPWD,
    key: resetInputConfig.value.code
  };
  isLoading.value = true;
  resetEmailPasswordService(par)
    .then(() => {
      isLoading.value = false;
      toast(t('reset_successfully'), { autoClose: 2000 });
      resetPassword.value = false;
      setPassword.value = false;
    })
    .catch((error) => {
      isLoading.value = false;
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    });
};
const clickLoginWithTelegram = async () => {
  if (website.value == '') {
    toast('Not currently supported', { autoClose: 2000, type: 'error' });
    return
  }
  window.Telegram.Login.auth({ bot_id: botId.value, request_access: 'write', embed: 1 }, (data) => {
    if (data) {
      console.log('Telegram.Login', data);
      const params = {
        data: data
      };
      // const appStore = useAppStore();
      if (appStore.fpPixelId) {
        params.facebook_pixel_id = appStore.fpPixelId;
      }
      if (appStore.fbToken) {
        params.fb_token = appStore.fbToken;
      }
      if (appStore.kwaiPixelId) {
        params.kwai_pixel_id = appStore.kwaiPixelId;
      }
      if (appStore.kwaiToken) {
        params.kwai_token = appStore.kwaiToken;
      }
      if (appStore.kwaiClickId) {
        params.click_id = appStore.kwaiClickId;
      }
      if (appStore.adsname) {
        params.adsname = appStore.adsname;
      }
      if (appStore.fpReferral) {
        params.invite_code = appStore.fpReferral;
      }
      isLoading.value = true;
      loginWithTelegramService(params)
        .then((res) => {
          isLoading.value = false;
          if (res.data.facebook_pixel_id) {
            if (!appStore.fpInit) {
              fpInit(res.data.facebook_pixel_id);
              appStore.fpInit = true;
            }
            if (res.data.status && res.data.status == 'register') {
              fpCompleteRegistrationEvent({ userId: res.data.user.pk });
            }
          }
          if (res.data.status && res.data.status == 'register') {
            gaSignUpEvent({ method: 'Telegram' });
            sendAdjustEventRegister();
          }
          sendAdjustEventLogin();
          setCookieAccessToken(res.data.access_token);
          setCookieUserId(res.data.user.pk);
          setCookieUsername(res.data.user.username);
          setCookieEmail(res.data.user.email);
          toast(t('login_successfully'), { autoClose: 2000 });
          loginFinish();
        })
        .catch((e) => {
          isLoading.value = false;
          toast(e.response.data.error, { autoClose: 3000, type: 'error' });
        });
    }
  });
};
const clickLoginWithGoogle = () => {
  if (website.value == '') {
    toast('Not currently supported', { autoClose: 2000, type: 'error' });
    return
  }
};
const callbackGoogle = (res) => {
  const callback = (response) => {
    console.log('Google.Login', response);
    const idToken = response.credential;
    if (idToken) {
      const params = {
        token: idToken
      };
      // const appStore = useAppStore();
      if (appStore.fpPixelId) {
        params.facebook_pixel_id = appStore.fpPixelId;
      }
      if (appStore.fbToken) {
        params.fb_token = appStore.fbToken;
      }
      if (appStore.kwaiPixelId) {
        params.kwai_pixel_id = appStore.kwaiPixelId;
      }
      if (appStore.kwaiToken) {
        params.kwai_token = appStore.kwaiToken;
      }
      if (appStore.kwaiClickId) {
        params.click_id = appStore.kwaiClickId;
      }
      if (appStore.adsname) {
        params.adsname = appStore.adsname;
      }
      if (appStore.fpReferral) {
        params.invite_code = appStore.fpReferral;
      }
      isLoading.value = true;
      googleAuth(params)
        .then((res) => {
          isLoading.value = false;
          if (res.data.facebook_pixel_id) {
            if (!appStore.fpInit) {
              fpInit(res.data.facebook_pixel_id);
              appStore.fpInit = true;
            }
            if (res.data.status && res.data.status == 'register') {
              fpCompleteRegistrationEvent({ userId: res.data.user.pk });
            }
          }
          if (res.data.status && res.data.status == 'register') {
            gaSignUpEvent({ method: 'Google' });
            sendAdjustEventRegister();
          }
          sendAdjustEventLogin();
          setCookieAccessToken(res.data.access_token);
          setCookieUserId(res.data.user.pk);
          setCookieUsername(res.data.user.username);
          setCookieEmail(res.data.user.email);
          setCookiesGmailLogin('true');
          toast(t('login_successfully'), { autoClose: 2000 });
          loginFinish();
        })
        .catch((error) => {
          isLoading.value = false;
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    } else {
      toast('credential is null', { autoClose: 3000, type: 'error' });
    }
  };
  callback(res);
};
const clickLogin = () => {
  let error = false;
  if (!inputConfig.value.login_email) {
    inputError('login_email', true, 'email_is_required');
    error = true;
  } else if (!inputConfig.value.login_email.includes('@')) {
    inputError('login_email', true, 'email_format___incorrect');
    error = true;
  } else {
    inputError('login_email', false);
  }
  if (!inputConfig.value.login_password.trim()) {
    inputError('login_password', true, 'password_is_required');
    error = true;
  } else {
    inputError('login_password', false);
  }
  if (error) {
    return;
  }
  const params = {
    email: inputConfig.value.login_email,
    password: md5(inputConfig.value.login_password + '{PWD_Salt_CC_Poker}').toString()
  };
  if (appStore.fpPixelId) {
    params.facebook_pixel_id = appStore.fpPixelId;
  }
  if (appStore.fbToken) {
    params.fb_token = appStore.fbToken;
  }
  if (appStore.kwaiPixelId) {
    params.kwai_pixel_id = appStore.kwaiPixelId;
  }
  if (appStore.kwaiToken) {
    params.kwai_token = appStore.kwaiToken;
  }
  if (appStore.kwaiClickId) {
    params.click_id = appStore.kwaiClickId;
  }

  isLoading.value = true;
  loginService(params)
    .then((res) => {
      isLoading.value = false;
      if (res.data.facebook_pixel_id) {
        if (!appStore.fpInit) {
          fpInit(res.data.facebook_pixel_id);
          appStore.fpInit = true;
        }
      }
      sendAdjustEventLogin();
      gaLoginEvent();
      setCookieAccessToken(res.data.access_token);
      setCookieUserId(res.data.user.pk);
      setCookieUsername(res.data.user.username);
      setCookieEmail(res.data.user.email);
      toast(t('login_successfully'), { autoClose: 2000 });
      loginFinish();
    })
    .catch((e) => {
      isLoading.value = false;
      for (let key in e.response.data) {
        toast(e.response.data[key], { autoClose: 3000, type: 'error' });
      }
    });
};
const clickSignup = async () => {
  let error = false;
  if (!inputConfig.value.sign_up_email) {
    inputError('sign_up_email', true, 'email_is_required');
    error = true;
  } else if (!inputConfig.value.sign_up_email.includes('@')) {
    inputError('sign_up_email', true, 'email_format___incorrect');
    error = true;
  } else {
    inputError('sign_up_email', false);
  }
  if (!inputConfig.value.sign_up_password.trim()) {
    inputError('sign_up_password', true, 'password_is_required');
    error = true;
  } else if (!handlePasswordIsVerify(inputConfig.value.sign_up_password)) {
    inputError('sign_up_password', true, 'characters_in___numbers');
    error = true;
  } else {
    inputError('sign_up_password', false);
  }
  if (error) {
    return;
  }
  isLoading.value = true;
  if (website.value != '') {
    await getGoogleToken();
  }
  if (googleToken.value > 0.5) {
    handleRegisterService();
  } else {
    loginStore.updateVerifyEmailShow(true);
  }

  isLoading.value = false;
};
const handlePasswordIsVerify = (password) => {
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,12}$/.test(password);
};

const handleRegisterService = (inputCode) => {
  const md5Pass = md5(inputConfig.value.sign_up_password + '{PWD_Salt_CC_Poker}').toString();
  const params = {
    email: inputConfig.value.sign_up_email,
    password1: md5Pass,
    password2: md5Pass,
    invite_code: inputConfig.value.sign_up_referral_code,
    g_score: googleToken.value || 0,
    from_website: true
  };
  if (inputCode) {
    params.key = inputCode;
  }
  if (appStore.adsname) {
    params.adsname = appStore.adsname;
  }
  if (appStore.fpPixelId) {
    params.facebook_pixel_id = appStore.fpPixelId;
  }
  if (appStore.fbToken) {
    params.fb_token = appStore.fbToken;
  }
  if (appStore.kwaiPixelId) {
    params.kwai_pixel_id = appStore.kwaiPixelId;
  }
  if (appStore.kwaiToken) {
    params.kwai_token = appStore.kwaiToken;
  }
  if (appStore.kwaiClickId) {
    params.click_id = appStore.kwaiClickId;
  }
  registerService(params)
    .then((res) => {
      if (res.data.facebook_pixel_id) {
        if (!appStore.fpInit) {
          fpInit(res.data.facebook_pixel_id);
          appStore.fpInit = true;
        }
        fpCompleteRegistrationEvent({ userId: res.data.user.pk });
      }
      sendAdjustEventRegister();
      gaSignUpEvent({ method: 'Email' });
      setCookieAccessToken(res.data.access_token);
      setCookieUserId(res.data.user.pk);
      setCookieUsername(res.data.user.username);
      setCookieEmail(res.data.user.email);
      toast(t('register_successfully'), { autoClose: 2000 });
      loginFinish();
    })
    .catch((e) => {
      toast(e.response.data.error, { autoClose: 3000, type: 'error' });
    });
};
</script>

<template>
  <wgDialog :show="props.show">
    <div class="dialog-content">
      <div class="content-left">
        <div class="tab-content">
          <div class="tab-div">
            <div v-if="loginStore.actType == 'login'" class="tab-item">{{ t('login') }}</div>
            <div v-if="loginStore.actType == 'sign_up'" class="tab-item">{{ t('sign_up') }}</div>
          </div>
        </div>
        <div class="input-content">
          <template v-if="loginStore.actType == 'login'">
            <div class="input-item">
              <div class="input-title">
                <div>{{ t('email') }}<span>*</span></div>
              </div>
              <div class="input-div" :class="setInputDivClass('login_email')">
                <input v-model="inputConfig.login_email" type="email" :placeholder="t('YourEmailAddress')" @focus="inputFocus('login_email')" @blur="inputBlur('login_email')" />
              </div>
              <div v-if="errorStatus['login_email'] && errorStatus['login_email'].show" class="input-error-tip">{{ t(errorStatus['login_email'].tip) }}</div>
            </div>
            <div class="input-item">
              <div class="input-title">
                <div>{{ t('password') }}<span>*</span></div>
                <div style="cursor: pointer" @click="clickForgetPassword">{{ t('forgot_password') }}</div>
              </div>
              <div class="input-div" :class="setInputDivClass('login_password')">
                <input v-model="inputConfig.login_password" :type="showPassword ? 'text' : 'password'" placeholder="********" @focus="inputFocus('login_password')" @blur="inputBlur('login_password')" />
                <div class="append" @click="setShowPassword">
                  <img v-if="showPassword" src="@/assets/images/login-and-register/Vector-3.svg" />
                  <img v-else src="@/assets/images/login-and-register/Vector-4.svg" />
                </div>
              </div>
              <div v-if="errorStatus['login_password'] && errorStatus['login_password'].show" class="input-error-tip">{{ t(errorStatus['login_password'].tip) }}</div>
            </div>
            <div class="input-item">
              <button class="btn-button" @click="clickLogin">{{ t('login') }}</button>
            </div>
          </template>
          <template v-if="loginStore.actType == 'sign_up'">
            <div class="input-item">
              <div class="input-title">
                <div>{{ t('email') }}<span>*</span></div>
              </div>
              <div class="input-div" :class="setInputDivClass('sign_up_email')">
                <input v-model="inputConfig.sign_up_email" type="email" :placeholder="t('YourEmailAddress')" @focus="inputFocus('sign_up_email')" @blur="inputBlur('sign_up_email')" />
              </div>
              <div v-if="errorStatus['sign_up_email'] && errorStatus['sign_up_email'].show" class="input-error-tip">{{ t(errorStatus['sign_up_email'].tip) }}</div>
            </div>
            <div class="input-item">
              <div class="input-title">
                <div>{{ t('password') }}<span>*</span></div>
              </div>
              <div class="input-div" :class="setInputDivClass('sign_up_password')">
                <input v-model="inputConfig.sign_up_password" :type="showPassword ? 'text' : 'password'" placeholder="********" @focus="inputFocus('sign_up_password')" @blur="inputBlur('sign_up_password')" />
                <div class="append" @click="setShowPassword">
                  <img v-if="showPassword" src="@/assets/images/login-and-register/Vector-3.svg" />
                  <img v-else src="@/assets/images/login-and-register/Vector-4.svg" />
                </div>
              </div>
              <div v-if="errorStatus['sign_up_password'] && errorStatus['sign_up_password'].show" class="input-error-tip">{{ t(errorStatus['sign_up_password'].tip) }}</div>
            </div>
            <div class="input-item">
              <div class="input-title" @click="isArrowUp = !isArrowUp">
                <div class="flex">{{ t('referral_code') }} <img class="img-arrow" :class="isArrowUp ? 'img-arrow-up' : ''" src="@/assets/images/home/Frame 33764.svg" /></div>
              </div>
              <template v-if="isArrowUp">
                <div class="input-div" :class="setInputDivClass('sign_up_referral_code')">
                  <input v-model="inputConfig.sign_up_referral_code" :placeholder="t('please_input_referral_code')" @focus="inputFocus('sign_up_referral_code')" @blur="inputBlur('sign_up_referral_code')" />
                </div>
                <div v-if="errorStatus['sign_up_referral_code'] && errorStatus['sign_up_referral_code'].show" class="input-error-tip">{{ t(errorStatus['sign_up_referral_code'].tip) }}</div>
              </template>
            </div>
            <div class="input-item">
              <button class="btn-button" @click="clickSignup">{{ t('join_us') }}</button>
            </div>
          </template>
        </div>
        <div class="btn-content">
          <template v-if="loginStore.actType == 'login'">
            <!-- <button class="btn-button" @click="clickLogin">{{ t('login') }}</button> -->
            <div class="input-tip">{{ t('login_tip') }}</div>
            <div class="btn-tabs">
              <div class="btn-tabs-tip">New to wingame?</div>
              <div class="btn-tabs-button" @click="clickTab('sign_up')">Create account</div>
            </div>
          </template>
          <template v-if="loginStore.actType == 'sign_up'">
            <!-- <button class="btn-button" @click="clickSignup">{{ t('join_us') }}</button> -->
            <div class="input-tip">{{ t('login_tip') }}</div>
            <div class="btn-tabs">
              <div class="btn-tabs-tip">Already have an account?</div>
              <div class="btn-tabs-button" @click="clickTab('login')">Login</div>
            </div>
          </template>
          <div class="btn-hr">
            <hr class="hr-item" />
            <div class="hr-text">{{ t('or') }}</div>
            <hr class="hr-item" />
          </div>
          <div class="btn-other-login">
            <div class="other-button" @click="clickLoginWithTelegram">
              <div class="button-content">
                <img src="@/assets/images/login-and-register/login-and-register-telegram.svg" />
                Telegram
              </div>
            </div>
            <div class="other-button" @click="clickLoginWithGoogle">
              <div class="button-content">
                <img src="@/assets/images/login-and-register/login-and-register-google.svg" />
                Google
              </div>
              <GoogleLogin v-if="website != ''" :callback="callbackGoogle" prompt class="custom-google-login-button"> </GoogleLogin>
              <!-- <GoogleLogin :callback="callbackGoogle" prompt :class="isShowGoogleLogin ? 'custom-google-login-button' : ''"> </GoogleLogin> -->
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <img v-if="!(isArrowUp && loginStore.actType == 'sign_up' && isMobile)" src="@/assets/images/new-version/loginImage.webp" />
        <div class="dialog-site">
          {{ t('accessing_site') }}<span @click="jump('/term_of_service')">{{ t('Terms&Conditions') }}</span>
        </div>
      </div>
      <button class="dialog-close" @click="closeMyself">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
          <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
        </svg>
      </button>
    </div>
    <VerifyEmail ref="refVerifyEmail" :show="loginStore.isVerifyEmailShow" :email="inputConfig.sign_up_email" @close="loginStore.updateVerifyEmailShow(false)" @nextAfter="handleRegisterService" />
    <div class="my-dialog reset-password-dialog" v-if="resetPassword">
      <div class="dialog-main">
        <div class="dialog-header">
          <div class="dialog-title">{{ t('reset_password') }}</div>
          <div class="close">
            <img src="../../assets/images/login-and-register/Vector.svg" @click="resetPassword = false" />
          </div>
        </div>
        <div class="dialog-body">
          <div class="bottom-1rem mt-4">
            {{ t('you_are___continue') }}
          </div>
          <div class="input-item">
            <div class="input-title">
              <div>{{ t('email') }}</div>
            </div>
            <div class="input-div">
              <input v-model="resetInputConfig.email" :placeholder="t('YourEmailAddress')" />
            </div>
          </div>
          <div style="display: flex; justify-content: center" class="reset-btn mt-5" @click="clickResetPasswordBtn">
            {{ t('reset') }}
          </div>
        </div>
      </div>
    </div>
    <div class="my-dialog reset-password-dialog" v-if="setPassword">
      <div class="dialog-main">
        <div class="dialog-header">
          <div class="dialog-title">{{ t('reset_password') }}</div>
          <div class="close">
            <img src="../../assets/images/login-and-register/Vector.svg" @click="setPassword = false" />
          </div>
        </div>
        <div class="dialog-body">
          <div class="input-item">
            <div class="input-title">
              <div>{{ t('password') }}</div>
            </div>
            <div class="input-div">
              <input v-model="resetInputConfig.password" :type="showPassword ? 'text' : 'password'" placeholder="********" />
              <div class="append" @click="setShowPassword">
                <img v-if="showPassword" src="@/assets/images/login-and-register/Vector-3.svg" />
                <img v-else src="@/assets/images/login-and-register/Vector-4.svg" />
              </div>
            </div>
          </div>
          <div style="width: 100%">
            <ul class="reset_password_ul" style="margin: 5px 0">
              <li style="font-size: 13px; line-height: 20px">{{ t('Contanis_Tip1') }}</li>
              <li style="font-size: 12px; line-height: 20px">{{ t('Contanis_Tip2') }}</li>
              <li style="font-size: 13px; line-height: 20px">{{ t('Contanis_Tip3') }}</li>
            </ul>
          </div>
          <div class="input-item">
            <div class="input-title">
              <div>{{ t('confirm_password') }}</div>
            </div>
            <div class="input-div">
              <input v-model="resetInputConfig.password2" :type="showPassword ? 'text' : 'password'" placeholder="********" />
              <div class="append" @click="setShowPassword">
                <img v-if="showPassword" src="@/assets/images/login-and-register/Vector-3.svg" />
                <img v-else src="@/assets/images/login-and-register/Vector-4.svg" />
              </div>
            </div>
          </div>
          <div class="input-item">
            <div class="input-title">
              <div>{{ t('verification') }}</div>
            </div>
            <div class="input-div">
              <input v-model="resetInputConfig.code" :placeholder="t('please_input___code')" />
              <div class="append">
                <div class="append-btn" @click="clickSendCode">{{ sendCodeText }}</div>
              </div>
            </div>
          </div>
          <div style="display: flex; justify-content: center" class="reset-btn mt-5" @click="clickSetPasswordBtn">
            {{ t('confirm') }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading" style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; z-index: 1600">
      <vue3-lottie :animationData="loadingAniJson" :width="100" :height="100"></vue3-lottie>
    </div>
  </wgDialog>
</template>

<style scoped>
.dialog-content {
  font-family: 'Poppins', sans-serif;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-radius: 10px;
  overflow: hidden;
  height: 590px;
  .content-left {
    width: 420px;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--my-dialog-main-bg-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 15px;

    .tab-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      text-align: center;

      .tab-div {
        display: flex;

        .tab-item {
          color: #e5f9ff;
          text-shadow:
            0px 0px 8px #1184fa,
            0px 0px 12px #1184fa;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 142%;
        }

        .selected {
          background-color: #3aa1ff;
          color: #0c131d;
          box-shadow:
            0px 0px 10px 0px rgba(17, 132, 250, 0.4),
            0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
            0px 0px 12px 0px #0d52b2 inset;
        }
      }
    }

    .input-content {
      flex-grow: 1;
      /* .input-item {
        position: relative;
        margin-top: 25px;
        .input-title {
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          color: #aac4ed;
          margin-bottom: 5px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          span {
            margin-left: 3px;
            color: #ef3737;
          }
          .img-arrow {
            margin-left: 5px;
            width: 10px;
          }
          .img-arrow-up {
            transform: rotate(180deg);
          }
        }
        .input-div {
          position: relative;
          background-color: #0d101e;
          border-radius: 6px;
          border: 1px solid #0d101e;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          input {
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            width: 100%;
            background-color: transparent;
            border: none;
            color: #acbecd;
            caret-color: #5b7bab;
            outline: 0;
            font-family: 'Poppins', sans-serif;
            letter-spacing: 0;
            text-align: left;
            padding: 11px 10px;
            box-sizing: border-box;
          }
          .append {
            margin: 0 10px;
            img {
              cursor: pointer;
              display: block;
              width: 20px;
            }
            .append-btn {
              cursor: pointer;
              background-color: #273854;
              color: #aac4ed;
              border-radius: 3px;
              text-wrap: nowrap;
              font-size: 12px;
              line-height: 1;
              font-weight: 400;
              padding: 7px 10px;
              min-width: 40px;
              text-align: center;
            }
          }
        }
        .focus {
          border-color: #92cbff;
        }
        .error {
          border-color: #ef3737;
        }
        .input-error-tip {
          position: absolute;
          color: #ef3737;
          font-size: 11px;
          margin-top: 2px;
        }
      } */
      .input-item:first-child {
        margin-top: 0;
      }
    }

    .btn-content {
      .input-tip {
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        color: #5b7bab;
        margin-top: 10px;
      }
      .btn-tabs {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-family: 'Poppins', sans-serif;
        gap: 5px;
        margin-top: 10px;
        .btn-tabs-tip {
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
          color: #5b7bab;
        }
        .btn-tabs-button {
          cursor: pointer;
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          color: #aac4ed;
        }
      }
      .btn-hr {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        padding: 0 5px;
        .hr-item {
          width: 33%;
          height: 1px;
          background-color: #aac4ed;
          border: 0;
        }
        .hr-text {
          color: #aac4ed;
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          margin: 0 20px;
        }
      }
      .btn-other-login {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        position: relative;
        .other-button {
          cursor: pointer;
          width: 50%;
          height: 40px;
          background-color: #273854;
          color: #aac4ed;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          position: relative;
          .button-content {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }
        }
      }
    }
    .btn-button {
      border: 0;
      cursor: pointer;
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      border-radius: 8px;
      background-color: #3aa1ff;
      color: #0c131d;
      box-shadow:
        0px 0px 10px 0px rgba(17, 132, 250, 0.4),
        0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
        0px 0px 12px 0px #0d52b2 inset;
    }
  }

  .content-right {
    width: 420px;
    position: relative;
    background-color: var(--my-dialog-main-bg-color);
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
    .dialog-site {
      color: #80a4db;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 20px;
      span {
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .dialog-close {
    background-color: transparent;
    cursor: pointer;
    border: none;
    padding: 6px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .dialog-close:hover {
    filter: brightness(0) invert(1);
  }
}
.reset-password-dialog {
  .dialog-main {
    width: 400px;
  }
  .dialog-header {
    height: 30px;
    .dialog-title {
      color: #e5f9ff;
      text-shadow:
        0px 0px 8px #1184fa,
        0px 0px 12px #1184fa;
    }
  }
}
.custom-google-login-button {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  opacity: 0.0001;
}
/* ::v-deep .S9gUrf-YoZ4jf {
  position: absolute !important;
  right: 0;
  top: 0;
  opacity: 0 !important;
} */
.input-item {
  position: relative;
  margin-top: 25px;
  .input-title {
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    color: #aac4ed;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      margin-left: 3px;
      color: #ef3737;
    }
    .img-arrow {
      margin-left: 5px;
      width: 10px;
    }
    .img-arrow-up {
      transform: rotate(180deg);
    }
  }
  .input-div {
    position: relative;
    background-color: #0d101e;
    border-radius: 6px;
    border: 1px solid #0d101e;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    input {
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      background-color: transparent;
      border: none;
      color: #acbecd;
      caret-color: #5b7bab;
      outline: 0;
      font-family: 'Poppins', sans-serif;
      letter-spacing: 0;
      text-align: left;
      padding: 11px 10px;
      box-sizing: border-box;
    }
    .append {
      margin: 0 10px;
      img {
        cursor: pointer;
        display: block;
        width: 20px;
      }
      .append-btn {
        cursor: pointer;
        background-color: #273854;
        color: #aac4ed;
        border-radius: 3px;
        text-wrap: nowrap;
        font-size: 12px;
        line-height: 1;
        font-weight: 400;
        padding: 7px 10px;
        min-width: 40px;
        text-align: center;
      }
    }
  }
  .focus {
    border-color: #92cbff;
  }
  .error {
    border-color: #ef3737;
  }
  .input-error-tip {
    position: absolute;
    color: #ef3737;
    font-size: 11px;
    margin-top: 2px;
  }
}
@media (max-width: 780px) {
  .dialog-content {
    height: 100%;
    flex-direction: column-reverse;
    .content-left {
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      .input-content {
        flex-grow: 0;
      }
    }
    .content-left {
      gap: 10px;
      .btn-content {
        .btn-hr {
          height: 50px;
        }
      }
    }
    .content-right {
      width: 100%;
      img {
        width: 100%;
        height: 140px;
        object-fit: cover;
        display: block;
      }
    }
  }
  .input-item {
    margin-top: 15px;
  }
}
</style>
