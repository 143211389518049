<template>
  <my-search-game />
  <div v-if="isProviderShow" @click.stop="isProviderShow = false" class="search-overlay svelte-o7w8d3 background-none" style=""></div>
  <div class="main-content phone-top-0">
    <div :class="'body slots_sort' + (isProviderShow ? ' showing' : '')">
      <div class="left">
        <div class="left" @click.stop="isProviderShow = !isProviderShow">
          <div class="web">
            <img src="../../assets/images/home/sort.svg" />
            <span class="left-sort">{{ t('sort_by') }}</span>
          </div>
          <div class="game_providers">
            {{ t('game_providers') }}
            <img src="../../assets/images/home/Frame 33764.svg" style="width: 0.5rem; height: 0.5rem; padding: 0 0 0 0.5rem" />
          </div>
        </div>

        <div class="providers_sort" v-show="isProviderShow" @click.stop="">
          <div v-for="(provider, index) in providerList" class="item" v-show="provider.name != 'PragmaticPlay LIVE'" @mouseover="provider.hover = true" @mouseleave="provider.hover = false" :key="index" @click="changeProviderChecked(provider)">
            <div v-if="!provider.isChecked" class="check-box"></div>
            <img v-if="provider.isChecked" style="padding: 0; width: 1.425rem; height: 1.425rem" src="../../assets/images/home/Sign.svg" />

            <div :style="'padding:0 0.4rem;' + (provider.hover ? 'color:#FFFFFF;' : '')">{{ provider.name }}</div>
            <div class="number">{{ provider.number || 0 }}</div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="phone clear"></div>
  <div class="slots" v-show="total > 0">
    <my-search-game-list :game-list="searchResult" @load-finish="loadFinish"></my-search-game-list>
  </div>
  <div class="main-content" v-show="total == 0">
    <div class="body">
      <div style="width: 100%; height: 12rem; justify-content: center; align-items: center">
        <div style="display: flex; justify-content: center; align-items: center; opacity: 0.5; margin-top: 5rem">
          <img src="../../assets/images/home/web_logo.svg" style="width: 13.6875rem; height: 4.375rem" />
        </div>
        <div class="no-data" v-if="game_code">{{ t('no_data') }}</div>
        <div class="no-data" v-if="!game_code">
          {{ t('no_data') }}
        </div>
      </div>
    </div>
  </div>
  <div class="main-content" v-show="total > 0">
    <div class="body">
      <div style="display: flex; justify-content: center; width: 100%; margin: 0.5rem 0" v-show="loadFinishFlag">
        <div style="background-color: #3a3e53; width: 12rem; border-radius: 0.5rem; height: 0.3rem">
          <div :style="'background-color: #43A0FF;width: ' + (total == 0 ? 12 : (searchResult.length / total) * 12) + 'rem;border-radius: 0.5rem;height: 0.3rem;'"></div>
        </div>
      </div>
      <div class="show-game-text" v-show="loadFinishFlag">{{ t('displaying') }}{{ searchResult.length }}/{{ total }}{{ t('games') }}</div>

      <div v-if="total > searchResult.length && loadFinishFlag" style="display: flex; justify-content: center; width: 100%; margin: 0.5rem 0">
        <div @click="next()" class="load-more">
          {{ t('load_more') }}
        </div>
      </div>
      <page-loading :animationData="jiazai" :container-height="'110px'" :show="!loadFinishFlag"></page-loading>
    </div>
  </div>
  <div class="phone login-dialog" style="" v-if="showLogin">
    <div class="login-parent">
      <my-login :is-dialog="true" @closeDialog="myCloseDialog" @loginAfter="loginAfter"></my-login>
    </div>
  </div>
</template>
<script setup>
import { getCookiesByKey, setCookiesByKey } from '@/assets/tools/CommonTool';
import jiazai from '@/assets/animation/expott_jiazai.json';
import pageLoading from '@/components/loading/pageLoading.vue';
import { useI18n } from 'vue-i18n';
import mySearchGameList from '@/components/game/searchGameList.vue';
import mySearchGame from '@/components/game/searchGame.vue';
import myLogin from '@/pages/login/myLogin.vue';

const { t } = useI18n();
</script>
<script>
import { getCookieAccessToken } from '@/assets/tools/CommonTool';
import { getGameProviders } from '@/assets/network/service/home';
import { openboxSearchGames } from '@/assets/network/service/game';
import { toast } from 'vue3-toastify';

export default {
  name: 'App',
  props: {
    game_code: {
      type: String,
      default: ''
    },
    providerShow: {
      type: Boolean,
      default: false
    },
    gameSearch: {
      type: Boolean,
      default: false
    },
    page_name: {
      type: String,
      default: ''
    },
    game_type: {
      type: String,
      default: ''
    },
    game_sub_type: {
      type: String,
      default: ''
    }
  },
  watch: {
    providerList: function (newVal) {
      if (this.provider_counts.length == 0) {
        for (let i_i in newVal) {
          newVal[i_i].number = 0;
        }
        return;
      }
      for (let i in newVal) {
        for (let i_i in this.provider_counts) {
          if (newVal[i].name == this.provider_counts[i_i].game_provider) {
            newVal[i].number = this.provider_counts[i_i].count;
            break;
          }
        }
      }
    },
    provider_counts: function (newVal) {
      if (this.providerList.length == 0) {
        return;
      }
      for (let i_i in this.providerList) {
        this.providerList[i_i].number = 0;
      }
      for (let i in newVal) {
        for (let i_i in this.providerList) {
          if (newVal[i].game_provider == this.providerList[i_i].name) {
            this.providerList[i_i].number = newVal[i].count;
            break;
          }
        }
      }
    },
    gameSearch: function (newVal) {
      this.isGameSearch = newVal;
    },
    providerShow: function (newVal) {
      console.log(newVal);
      this.isProviderShow = newVal;
    }
  },
  components: {
    // myI18n
  },
  beforeUnmount() {},
  data() {
    return {
      loadFinishFlag: true,
      searchText: '',
      isGameSearch: false,
      searchResult: [],
      pageIndex: 0,
      total: 0,
      provider_counts: [],
      isProviderShow: false,
      providerShow2: false,
      providerList: [],
      searchParams: {
        game_type: '',
        name: '',
        start: 0,
        end: 20,
        page_name: '',
        game_provider: [],
        game_sub_type: ''
      },
      showLogin: false
    };
  },
  created() {
    this.searchParams.game_type = this.game_type;
    this.searchParams.page_name = this.page_name;
    this.searchParams.game_sub_type = this.game_sub_type;
    this.getGameProviders();
    if (this.game_code == 'favorites' || this.game_code == 'recent') {
      let token = getCookieAccessToken();
      if (!token) {
        this.showLogin = true;
        this.$router.push('/login?type=sign_up');
      }
    } else {
      this.showLogin = false;
    }
    if (!this.showLogin) {
      setTimeout(this.getSlotsListService,50)
    }
  },
  mounted() {},
  methods: {
    myCloseDialog() {
      this.showLogin = false;
    },
    loginAfter() {
      this.getSlotsListService();
    },
    loadFinish() {
      this.loadFinishFlag = true;
    },
    getCounts(provider) {
      for (let i_i in this.provider_counts) {
        if (provider.name == this.provider_counts[i_i].provider_counts) {
          provider.number = this.provider_counts[i_i].count;
          break;
        }
      }
    },
    getGameProviders() {
      getGameProviders()
        .then((response) => {
          this.providerList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeProviderChecked(provider) {
      provider.isChecked = !provider.isChecked;
      console.log(this.providerList);
      let providers = [];
      for (let i in this.providerList) {
        if (this.providerList[i].isChecked) {
          providers.push(this.providerList[i].name);
        }
      }
      this.searchParams.game_provider = providers;
      this.pageIndex = 0;
      this.getSlotsListService();
    },
    next() {
      this.loadFinishFlag = false;
      this.pageIndex++;
      this.getSlotsListService();
    },
    getSlotsListService() {
      const that = this;
      // 先获取在设置为空，是为了只在第一次加载数据的时候获取cookies中记录的页面大小
      let pageIndex = getCookiesByKey('new_games_page_size')
      setCookiesByKey('new_games_page_size', '')
      if(pageIndex){
        this.pageIndex = pageIndex
      }
      if(pageIndex){
        this.searchParams.start = 0;
        this.searchParams.end = this.pageIndex * 20 + 20;
      }else{
        this.searchParams.start = this.pageIndex * 20;
        this.searchParams.end = this.searchParams.start + 20;
      }
      setCookiesByKey('games_page_size', this.pageIndex)
      this.loadFinishFlag = false;

      openboxSearchGames(that.searchParams)
        .then((response) => {
          if (response.data.data.length == 0) {
            this.loadFinishFlag = true;
          }
          if (this.pageIndex !== 0) {
            this.searchResult = this.searchResult.concat(response.data.data);
          } else {
            this.searchResult = response.data.data;
          }
          this.provider_counts = response.data.provider_counts;
          this.total = response.data.total_count;
        })
        .catch((error) => {
          this.loadFinishFlag = true;
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    profitChange(indexProfit) {
      this.indexProfit = indexProfit;
    },
    enterSearch() {
      this.searchResult = [];
      this.searchParams.name = this.searchText;
      this.pageIndex = 0;
      this.getSlotsListService();
    },
    searchBlur() {},
    searchFocus() {
      this.isGameSearch = true;
    }
  }
};
</script>

<style spaced>
.slots_sort.showing {
  position: relative;
  z-index: var(--showing-z-index);
}
::v-deep .web .phone.login-dialog {
  height: 0;
}
</style>
