import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import { walletDepositInfoService, walletGenerateNewAddressService, getFiatPreDeposit, fiat_pre_withdraw } from '@/assets/network/service/wallet';

const useWalletStore = defineStore('Wallet', {
  state: () => ({
    tokensInfoList: [],
    cryptoSelectItem: null,
    fiatSelectItem: null,
    cryptoSearchInput: '',
    fiatSearchInput: '',
    cryptoChainIndex: 0,

    fiatDepositOpt: [],
    fiatDepositAmount: '0',

    cryptoWithdrawAddress: '',
    cryptoWithdrawAmount: '0',
    fiatWithdrawOptItem: null,
    fiatWithdrawOpt: [],
    fiatWithdrawAmount: '0',

    showDialogAddPix: false,
    showDialogDeposit: false,
    showDialogVerification: false,
    showDialogPIN: false,

    isLoading: false
  }),
  getters: {
    cryptoList(state) {
      return state.tokensInfoList.filter((item) => !item.is_fiat);
    },
    fiatList(state) {
      return state.tokensInfoList.filter((item) => item.is_fiat);
    },
    searchCryptoList(state) {
      let res = this.cryptoList;
      if (state.cryptoSearchInput) {
        res = this.cryptoList.filter((item) => item.symbol.toLowerCase().includes(state.cryptoSearchInput.toLowerCase()));
      }
      if (res) {
        return res;
      } else {
        return [];
      }
    },
    searchFiatList(state) {
      let res = this.fiatList;
      if (state.fiatSearchInput) {
        res = this.fiatList.filter((item) => item.symbol.toLowerCase().includes(state.fiatSearchInput.toLowerCase()));
      }
      return res;
    }
  },
  actions: {
    async getTokensInfo(_type) {
      const res = await walletDepositInfoService({ type: _type });
      if (res.data.code == 200) {
        this.tokensInfoList = res.data.data;
        if (this.cryptoSelectItem == null) {
          this.cryptoSelectItem = this.cryptoList[0];
        } else {
          const fil1 = this.cryptoList.filter((item) => item.symbol == this.cryptoSelectItem.symbol);
          this.cryptoSelectItem = fil1[0]
        }
        if (this.fiatSelectItem == null) {
          this.fiatSelectItem = this.fiatList[0];
        } else {
          const fil2 = this.fiatList.filter((item) => item.symbol == this.fiatSelectItem.symbol);
          this.fiatSelectItem = fil2[0]
        }
      } else {
        toast(res.data.msg, { autoClose: 2000, type: 'error' });
      }
    },
    async getCryptoChainAddress() {
      const params = {
        symbol: this.cryptoSelectItem.symbol,
        platform: this.cryptoSelectItem.chains[this.cryptoChainIndex].platform
      };
      const res = await walletGenerateNewAddressService(params);
      if (res.data.code == 200) {
        this.cryptoSelectItem.chains[this.cryptoChainIndex].address = res.data.data.address;
      } else {
        toast(res.data.msg, { autoClose: 2000, type: 'error' });
      }
    },
    async getFiatDepositOpt() {
      const res = await getFiatPreDeposit({ symbol: this.fiatSelectItem.symbol });
      if (res.data.code == 200) {
        this.fiatDepositOpt = res.data.data.deposit_level_config;
      } else {
        toast(res.data.msg, { autoClose: 2000, type: 'error' });
      }
    },
    async getFiatWithdrawOpt() {
      const res = await fiat_pre_withdraw({ symbol: this.fiatSelectItem.symbol });
      if (res.data.code == 200) {
        this.fiatWithdrawOpt = res.data.data.pay_info;
      } else {
        toast(res.data.msg, { autoClose: 2000, type: 'error' });
      }
    }
  }
});

export default useWalletStore;
