import api from '../api';
import httpManager from '../httpManager';
// 谷歌验证
export const googleTokenService = (params) => {
  return httpManager({
    url: api.googleVitality,
    method: 'get',
    params
  });
};
export const getTGDrainageConfigService = (params) => {
  return httpManager({
    url: api.tgDrainageConfig,
    method: 'get',
    params
  });
};

// 邮箱登录
export const loginService = (param) => {
  return httpManager({
    url: api.authLogin,
    method: 'post',
    data: param
  });
};

// 邮箱注册
export const registerService = (param) => {
  return httpManager({
    url: api.authRegistrationConfirmCode,
    method: 'post',
    data: param
  });
};

// 邮箱注册发送验证码
export const registerEmailSendCodeService = (param) => {
  return httpManager({
    url: api.authRegistrationSendCode,
    method: 'post',
    data: param
  });
};

// 注册是否需要强势输入邀请码
export const registerForceInputInviteCodeService = () => {
  return httpManager({
    url: api.gameGetGameConfig,
    method: 'post',
    data: {}
  });
};

// 重置邮箱登录密码
export const resetEmailPasswordService = (param) => {
  return httpManager({
    url: api.authRegistrationResetPasswordConfirmCode,
    method: 'post',
    data: param
  });
};

// 重置邮箱登录密码 发送验证码
export const resetEmailPasswordSendCodeService = (param) => {
  return httpManager({
    url: api.authRegistrationResetPasswordSendCode,
    method: 'post',
    data: param
  });
};

// 退出登录
export const logoutService = () => {
  return httpManager({
    url: api.authLogout,
    method: 'post',
    data: {}
  });
};

// 获取用户信息
export const userInfoService = (userId) => {
  return httpManager({
    url: api.playeruser + `${userId}/`,
    method: 'get',
    data: {}
  });
};

// Telegram登录
export const loginWithTelegramService = (param) => {
  return httpManager({
    url: api.authTelegramRegister,
    method: 'post',
    data: param
  });
};

// 更新用户信息
export const updateUserInfoService = (param) => {
  const { userId } = param;
  return httpManager({
    url: api.playeruser + `${userId}/`,
    method: 'put',
    data: param
  });
};

// 绑定邀请码
export const bindInviteCodeService = (param) => {
  return httpManager({
    url: api.authBindInviteCode,
    method: 'post',
    data: param
  });
};

// TG Bot 里快捷授权登录
export const tgBotDisposableLoginService = (param) => {
  return httpManager({
    url: api.authTelegramDisposableLogin,
    method: 'post',
    data: param
  });
};

// TG Bot 里快捷授权登录
export const getTransactionsList = (param) => {
  return httpManager({
    url: api.getTransactionsList,
    method: 'post',
    data: param
  });
};

// 第三方登录绑定邮箱 发送验证码
export const bindEmailSendCodeService = (param) => {
  return httpManager({
    url: api.player_user_send_bind_email_code,
    method: 'post',
    data: param
  });
};

// 第三方登录绑定邮箱
export const bindEmailService = (param) => {
  return httpManager({
    url: api.player_user_bind_email,
    method: 'post',
    data: param
  });
};
export const sendBindOtpEmailCode = (param) => {
  return httpManager({
    url: api.send_bind_otp_email_code,
    method: 'post',
    data: param
  });
};
export const verifyOtpEmailCode = (param) => {
  return httpManager({
    url: api.vefify_otp_email_code,
    method: 'post',
    data: param
  });
};
export const bindOtp = (param) => {
  return httpManager({
    url: api.bind_otp,
    method: 'post',
    data: param
  });
};

export const googleAuth = (param) => {
  return httpManager({
    url: api.googleAuth,
    method: 'post',
    data: param
  });
};

// 更新用户的pixelId
export const updateUserFBPixelIdService = (param) => {
  return httpManager({
    url: api.playerUserUpdateFacebookPixelId,
    method: 'post',
    data: param
  });
};

// 落地页注册
export const createAllianceMemberService = (param) => {
  return httpManager({
    url: api.playerUserCreateAllianceMember,
    method: 'post',
    data: param
  });
};

// 个人投注信息统计查询接口
export const getBetInfo = () => {
  return httpManager({
    url: api.getBetInfo,
    method: 'get',
    data: {}
  });
};
// 用户下注信息
export const userBetInfoService = (param) => {
  return httpManager({
    url: api.playerUserBetInfo,
    method: 'get',
    data: param
  });
};
// 获取telegrambotId
export const getTGBotIdService = (param) => {
  return httpManager({
    url: api.getTGBotId,
    method: 'get',
    data: param
  });
};
// kwai事件上报
export const kwaiEventService = (param) => {
  return httpManager({
    url: api.kwaiEvent,
    method: 'post',
    data: param
  });
};
