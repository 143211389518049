<script setup>
import NewDeposit from '@/components/wallet/NewDeposit';

import useWalletStore from '@/store/wallet';

const walletStore = useWalletStore();
</script>

<template>
  <el-dialog v-model="walletStore.showDialogDeposit" :close-on-click-modal="false" class="bg-[#191F33] text-left w-full md:w-[500px]">
    <!-- <NewDeposit :tabIndex="route.query.tab" :defSymbol="route.query.symbol" /> -->
    <NewDeposit />
  </el-dialog>
</template>
