// src\react-i18next\i18n.js
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import es from './locales/es.json';
import ko from './locales/ko.json';
import pt from './locales/pt.json';
// import zh from "./locales/zh.json";
import tr from './locales/tr.json';

function loadLocaleMessages() {
  const locales = [{ en: en }, { es: es }, { ko: ko }, { pt: pt }, { tr: tr }];
  const messages = {};
  locales.forEach((lang) => {
    console.log(lang);
    const key = Object.keys(lang);
    messages[key] = lang[key];
  });
  return messages;
}

const lang = localStorage.getItem('locale') || 'en';
export default createI18n({
  locale: lang,
  // fallbackLocale: "en",
  legacy: false,
  messages: loadLocaleMessages()
});
