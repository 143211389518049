<script setup>
import { ref, watch, defineExpose } from 'vue';
import spinCenterBg from '@/assets/images/spin/zp_01.png'
import spiButtonBg from '@/assets/images/spin/zp_03.png'


defineOptions({
    name: 'Turntable'
})
const bgColorList = [
    '#CF4F87',
    '#DC8252',
    '#9226FE',
    '#3574D1',
    '#5A38ED',
    '#92B854',
    '#B0298A'
]

const emits = defineEmits(['beforeStartCallback', 'endCallback'])

const props = defineProps({
    sections: {
        type: [Array],
        default: () => []
    },
});
const blocks = ref([
    {
        padding: '13px',
        imgs: [{
            src: spinCenterBg,
            width: '21rem',
            height: '21rem',
        }]
    }])
const prizes = ref([])

// 监听 sections 的变化
watch(() => props.sections, (val) => {
    prizes.value = []
    prizes.value = val.map((item, index) => {
        return {
            fonts: [{ text: item.text, top: '50%', fontColor: '#fff', fontWeight: 700, fontSize: '0.75rem' }],
            background: bgColorList[index % 7],
            imgs: item.url ? [{
                src: item.url,
                width: '1rem',
                height: '1rem',
                top: '20%'
            }] : [],
            ...item
        }
    });
}, {
    immediate: true,
    deep: true
})
const buttons = ref([{
    radius: '35%',
    pointer: true,
    imgs: [{
        src: spiButtonBg,
        width: '5rem',
        height: '5rem',
        top: '-40'
    }]
}])

// 创建对抽奖组件的引用
const myLucky = ref(null)


// 点击抽奖按钮会触发star回调
const startCallback = () => {
    emits('beforeStartCallback')
}

// 抽奖结束会触发end回调
const endCallback = (prize) => {
    emits('endCallback', prize)
}

const play = () => {
    myLucky.value.play()
};

const stop = (index) => {
    myLucky.value.stop(index)
};


const init = () => {
    myLucky.value.init()
};




defineExpose({
    play,
    stop,
    init
});


</script>

<template>
    <div class="flex justify-center">
        <div id="myLucky" class="relative">
            <img class="absolute top-0 right-[50%] w-[3rem] z-50"
                style="right: 50%; transform: translateX(50%) rotate(-90deg);"
                src="../../../assets//images/spin/zp_02.png" alt="">
            <LuckyWheel ref="myLucky" width="21rem" height="21rem" :prizes="prizes" :blocks="blocks" :buttons="buttons"
                @start="startCallback" @end="endCallback" />
        </div>
    </div>

</template>
