<script setup>
// 导入
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import useWalletStore from '@/store/wallet';
import { transformedStr } from '@/assets/tools/CommonTool.js';
import DialogAddPix from '@/components/wallet/module/DialogAddPix';
// 传参
const props = defineProps({
  titleText: {
    type: String
  }
});
// 属性
const { t } = useI18n();
const walletStore = useWalletStore();
// 计算属性
// 生命周期
// 方法
const clickAddPix = () => {
  if (walletStore.fiatSelectItem.symbol == 'BRL') {
    walletStore.showDialogAddPix = true;
  }
};
</script>

<template>
  <div class="relative">
    <div class="text-[12px] text-[#5B7BAB] text-left font-[700] mb-[5px]">{{ props.titleText }}</div>
    <el-select v-model="walletStore.fiatWithdrawOptItem" class="my-el-css w-1/2" :placeholder="t('Please select')" size="large" :teleported="false">
      <template #label>
        <div v-if="walletStore.fiatSelectItem.symbol == 'BRL'" class="flex items-center gap-[5px]">
          <span>{{ transformedStr(walletStore.fiatWithdrawOptItem.person_name, 3, 1, 5, '*') }}</span>
          <span>{{ transformedStr(walletStore.fiatWithdrawOptItem.person_id, 3, 4) }}</span>
          <span class="text-[#8ea3c3]">{{ walletStore.fiatWithdrawOptItem.account_type }}</span>
        </div>
        <div v-else-if="walletStore.fiatSelectItem.symbol == 'MXN'" class="flex items-center gap-[5px]">
          <span>{{ transformedStr(walletStore.fiatWithdrawOptItem.account_name, 3, 1, 5, '*') }}</span>
          <span>{{ transformedStr(walletStore.fiatWithdrawOptItem.account_id, 3, 4) }}</span>
          <span class="text-[#8ea3c3]">{{ walletStore.fiatWithdrawOptItem.account_type }}</span>
        </div>
      </template>
      <el-option v-for="(item, index) in walletStore.fiatWithdrawOpt" :key="index" :value="item">
        <div class="flex justify-between items-center">
          <div v-if="walletStore.fiatSelectItem.symbol == 'BRL'" class="flex items-center gap-[5px]">
            <span>{{ transformedStr(item.person_name, 3, 1, 5, '*') }}</span>
            <span>{{ transformedStr(item.person_id, 3, 4) }}</span>
            <span class="text-[#8ea3c3]">{{ item.account_type }}</span>
          </div>
          <div v-else-if="walletStore.fiatSelectItem.symbol == 'MXN'" class="flex items-center gap-[5px]">
            <span>{{ transformedStr(item.account_name, 3, 1, 5, '*') }}</span>
            <span>{{ transformedStr(item.account_id, 3, 4) }}</span>
            <span class="text-[#8ea3c3]">{{ item.account_type }}</span>
          </div>
        </div>
      </el-option>
      <template #footer>
        <div class="copy-btn text-[12px] font-[700] rounded-[4px] py-[8px]" @click="clickAddPix">
          {{ t('add_pix').toUpperCase() }}
        </div>
      </template>
    </el-select>
    <DialogAddPix v-if="walletStore.showDialogAddPix" />
  </div>
</template>

<style scoped>
.copy-btn {
  cursor: pointer;
  background-color: #222942;
  color: #fff;
  box-shadow:
    0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
    0px 0px 12px 0px #142034 inset;
}
</style>
