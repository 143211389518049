<script setup>
// 导入
import { ref, computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import menus from '@/pages/menus/myMenus.vue';
import myHeader from '@/components/myHeader.vue';
import myFooter from '@/components/footer/footer.vue';
import { useRoute } from 'vue-router';
import { getPromotionDetail } from '@/assets/network/service/home';
import ButtonItem from './module/button-item.vue';
import { formatTimeStamp, isTimeInRange } from '@/assets/tools/CommonTool';
import { joinPromotion, receivePromotion } from '@/assets/network/service/home';

const { t } = useI18n();
const route = useRoute();
// 传参
// 属性
const detailData = ref(null)
// 计算属性
const btnText = computed(() => {
  return getBtnText()
});
// 生命周期
onBeforeMount(async () => {
  getPromotionDetailData()
});
// 方法
const getPromotionDetailData = async ()=> {
  const res = await getPromotionDetail({id: route.query.id})
  detailData.value = res.data.data;
}

const getPromotionStatus = (item)=> {
  // 1 进行中 2 已结束 3 加入 4 领奖
  const startTime = formatTimeStamp(item.start_time)
  const endTime = formatTimeStamp(item.end_time)
  const currentTime = formatTimeStamp()
  const isProgress = isTimeInRange(startTime, endTime, currentTime)
  if (item.is_prize) {
    return 4
  }
  else if (item.pre_condition?.is_join) {
    const joinStartTime = formatTimeStamp(item.pre_condition.join_start_time)
    const joinEndTime = formatTimeStamp(item.pre_condition.join_end_time)
    const isJoinTime = isTimeInRange(joinStartTime, joinEndTime, currentTime)
    if (item.is_joined) {
      if (isJoinTime) {
        return 1
      } else {
        if (isProgress) {
          return 1
        } else {
          return 2
        }
      }
    } else {
      if (isJoinTime) {
        return 3
      } else {
        if (isProgress) {
          return 1
        } else {
          return 2
        }
      }
    }
  }
  else if (isProgress) {
    return 1
  } else {
    return 2
  }
}

const getBtnText = ()=> {
  const status = getPromotionStatus(detailData.value)
  if (status == 3) {
    return t('Join_In').toLocaleUpperCase()
  } else if (status == 4) {
    return t('Claim_It').toLocaleUpperCase()
  } else {
    return ''
  }
}

const clickBtn = async ()=> {
  const status = getPromotionStatus(detailData.value)
  if (status == 3) {
    try {
      const res = await joinPromotion({id: detailData.value.id})
      if (res.data.code == 200) {
        toast(t('success'), { autoClose: 2000, type: 'success' });
        getPromotionDetailData();
      } else {
        toast(res.data.msg, { autoClose: 2000, type: 'error' });
      }
    } catch (error) {
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    }
  } else if (status == 4) {
    try {
      const res = await receivePromotion({promotion_id: detailData.value.id})
      if (res.data.code == 200) {
        toast(t('success'), { autoClose: 2000, type: 'success' });
        getPromotionDetailData();
      } else {
        toast(res.data.msg, { autoClose: 2000, type: 'error' });
      }
    } catch (error) {
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    }
  }
}
</script>

<template>
  <div class="root_div home" @click="isGameSearch = false">
    <menus @menuChange="menuChange"></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <myHeader :showLogin="showLogin" @loginAfter="loginAfter" @hideShowLogin="hideShowLogin"></myHeader>
      <div class="main-content">
        <div class="body">
          <div v-if="detailData" class="pt-[20px] text-[#8ea3c3] leading-normal md:w-[650px] w-full text-left">
            <div class="flex mb-[20px]">
              <div class="bg-[#1C243F] rounded-[6px] px-[16px] py-[10px] text-[14px] font-[700] leading-none flex">
                <div class="text-[#5B7BAB]">{{ $t('promotion').toLocaleUpperCase() }}</div>
                <div class="text-[#5B7BAB] mx-[5px] text-[15px]">></div>
                <div class="text-[#FFFFFF]">{{ $t('details').toLocaleUpperCase() }}</div>
              </div>
            </div>
            <img class="w-full block" :src="detailData.image_url2"/>
            <div class="my-[20px]" v-html="detailData.promotion_content"></div>
            <div class="flex justify-center items-center">
              <ButtonItem v-if="btnText != ''" :btnText="btnText" @click.stop="clickBtn"/>
            </div>
          </div>
        </div>
      </div>
      <myFooter :isHomeFooter="true"></myFooter>
    </div>
  </div>
</template>

<style scoped>
</style>
