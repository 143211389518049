<script setup>
// 导入
import { ref, defineProps, onBeforeMount, watch, watchEffect } from 'vue';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import useWalletStore from '@/store/wallet';
import { fiat_deposit } from '@/assets/network/service/wallet';
import '@/components/wallet/module/MyElement.css';
import TabSelector from '@/components/wallet/module/TabSelector';
import CoinSelector from '@/components/wallet/module/CoinSelector';
import ChainSelector from '@/components/wallet/module/ChainSelector';
import AddressView from '@/components/wallet/module/AddressView';
import NociceView from '@/components/wallet/module/NociceView';
import ButtonItem from '@/components/wallet/module/ButtonItem';
import { Vue3Lottie } from 'vue3-lottie';
import loadingAniJson from '@/assets/animation/export_sclogo_wingame.json';
import { getBrowser } from '@/assets/tools/CommonTool';
import { useAppStore } from '@/store/store';
import { kwaiEventService } from '@/assets/network/service/user';
// 传参
const props = defineProps({
  tabIndex: {
    type: String,
    default: 'Crypto'
  },
  defSymbol: {
    type: String
  }
});
// 属性
const { t } = useI18n();
const route = useRoute();
const appStore = useAppStore();
const walletStore = useWalletStore();
const tabSelect = ref(props.tabIndex);
// 计算属性
watch([() => walletStore.cryptoSelectItem, () => walletStore.cryptoChainIndex], () => {
  if (!walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].address) {
    walletStore.getCryptoChainAddress();
  }
});
watch([() => walletStore.fiatSelectItem], () => {
  walletStore.getFiatDepositOpt();
});
// 生命周期
onBeforeMount(async () => {
  await walletStore.getTokensInfo(1);
  if (props.defSymbol) {
    const res = walletStore.tokensInfoList.filter((item) => item.symbol.toLowerCase() == props.defSymbol.toLowerCase());
    if (props.tabIndex == 'Crypto') {
      walletStore.cryptoSelectItem = res[0];
    }
    if (props.tabIndex == 'Fiat') {
      walletStore.fiatSelectItem = res[0];
    }
  }
});
watchEffect(() => {
  if(tabSelect.value == 'Crypto' && walletStore.cryptoSelectItem) {
    const params = {
        kwai_pixel_id: appStore.kwaiPixelId,
        kwai_token: appStore.kwaiToken,
        click_id: appStore.kwaiClickId,
        event_name: 'deposit_now',
        symbol: walletStore.cryptoSelectItem.symbol,
        amount: 0
      }
      kwaiEventService(params);
  }
});
// 方法
const clickTab = (type) => {
  tabSelect.value = type;
};
const clickGear = (item) => {
  walletStore.fiatDepositAmount = item.amount;
};
const getFiatDepositLimit = () => {
  //{{ `${walletStore.fiatSelectItem.chains[0].min_deposit_amount} - ${walletStore.fiatSelectItem.chains[0].max_deposit_amount} ${walletStore.fiatSelectItem.symbol}` }}
  let min, max;
  if (walletStore.fiatSelectItem.chains[0].min_deposit_amount > 0) {
    min = walletStore.fiatSelectItem.chains[0].min_deposit_amount;
  } else {
    min = '0';
  }
  if (walletStore.fiatSelectItem.chains[0].max_deposit_amount > 0) {
    max = walletStore.fiatSelectItem.chains[0].max_deposit_amount;
  } else {
    max = '∞';
  }
  if (min == '0' && max == '∞') {
    return '';
  } else {
    return `${min} - ${max} ${walletStore.fiatSelectItem.symbol}`;
  }
};
const clickDepositNow = async () => {
  if (walletStore.isLoading) {
    return;
  }
  if (!walletStore.fiatDepositAmount) {
    toast(t('deposit_amount_is_required'), { autoClose: 2000, type: 'error' });
    return;
  }
  if (Number(walletStore.fiatDepositAmount) < Number(walletStore.fiatSelectItem.chains[0].min_deposit_amount)) {
    toast(t('the_minimum_recharge') + ' ' + walletStore.fiatSelectItem.chains[0].min_deposit_amount + walletStore.fiatSelectItem.symbol, { autoClose: 2000, type: 'error' });
    return;
  }
  if (walletStore.fiatSelectItem.chains[0].max_deposit_amount > 0 && Number(walletStore.fiatDepositAmount) > Number(walletStore.fiatSelectItem.chains[0].max_deposit_amount)) {
    toast(t('the_maximum_recharge') + ' ' + walletStore.fiatSelectItem.chains[0].max_deposit_amount + walletStore.fiatSelectItem.symbol, { autoClose: 2000, type: 'error' });
    return;
  }
  const par = {
    symbol: walletStore.fiatSelectItem.symbol,
    amount: Number(walletStore.fiatDepositAmount),
    pageUrl: route.path
  };
  walletStore.isLoading = true;
  try {
    const browser = getBrowser();
    console.log('当前浏览器是：', browser);
    let win;
    if (browser == 'Safari') {
      win = window.open('', '_blank');
    }
    const res = await fiat_deposit(par);
    walletStore.isLoading = false;
    if (res.data.code == 200) {
      if (browser == 'Safari') {
        win.location = res.data.data.cashierUrl;
      } else {
        window.open(res.data.data.cashierUrl, '_blank');
      }

      const params = {
        kwai_pixel_id: appStore.kwaiPixelId,
        kwai_token: appStore.kwaiToken,
        click_id: appStore.kwaiClickId,
        event_name: 'deposit_now',
        symbol: walletStore.fiatSelectItem.symbol,
        amount: walletStore.fiatDepositAmount
      }
      kwaiEventService(params);
    } else {
      toast(res.data.msg, { autoClose: 2000, type: 'error' });
    }
  } catch (error) {
    walletStore.isLoading = false;
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 2000, type: 'error' });
  }
};
</script>

<template>
  <div v-if="walletStore.tokensInfoList && walletStore.tokensInfoList.length > 0" class="relative box-border max-w-[480px] bg-[#191F33]">
    <!-- 标题 -->
    <div class="flex flex-row justify-between items-center mb-[15px]">
      <div class="text-[18px] text-[#ffffff] font-[700]">{{ t('deposit') }}</div>
      <!-- <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
          <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
        </svg>
      </div> -->
    </div>
    <!-- tab选项 -->
    <TabSelector class="mb-[15px]" :tabIndex="tabSelect" @change="clickTab" />
    <!-- 加密货币 -->
    <div v-show="tabSelect == 'Crypto'">
      <div class="flex justify-between gap-[10px] mb-[15px]">
        <CoinSelector class="w-1/2" :titleText="t('Deposit Currency').toUpperCase()" :tabIndex="tabSelect" />
        <ChainSelector class="w-1/2" :titleText="t('Choose Network').toUpperCase()" />
      </div>
      <AddressView class="mb-[15px]" :titleText="t('Deposit Address').toUpperCase()" />
      <NociceView type="Deposit" />
    </div>
    <!-- 法币 -->
    <div v-show="tabSelect == 'Fiat'">
      <div class="flex mb-[15px]">
        <CoinSelector class="w-full flex justify-between items-center" :titleText="t('Deposit Currency').toUpperCase()" :tabIndex="tabSelect" />
      </div>
      <div class="mb-[15px]">
        <div class="flex justify-between items-center text-[12px] text-[#5B7BAB] text-right font-[700] mb-[5px]">
          <div>{{ t('Deposit Amount').toUpperCase() }}</div>
          <div>{{ getFiatDepositLimit() }}</div>
        </div>
        <el-input v-model="walletStore.fiatDepositAmount" class="my-el-css" size="large" clearable>
          <template #prefix>
            <img class="w-[16px]" :src="walletStore.fiatSelectItem.icon_url" />
          </template>
        </el-input>
      </div>
      <div class="grid grid-cols-3 gap-[10px] mb-[15px]">
        <div class="bg-[#222942] rounded-[4px] relative h-[60px] flex items-center px-[20px] gap-[10px] cursor-pointer" v-for="(item, index) in walletStore.fiatDepositOpt" :key="index" @click="clickGear(item)">
          <img class="w-[26px]" :src="walletStore.fiatSelectItem.icon_url" />
          <div class="text-[#aac4ed]">{{ item.amount }}</div>
          <div v-if="item.desc" class="absolute top-0 right-0 bg-[#e72c2c] rounded-tr-[4px] rounded-bl-[4px] text-[12px] text-[#fff] px-[5px] py-[2px]">
            {{ item.desc }}
          </div>
        </div>
      </div>
      <div class="w-full mb-[15px]">
        <ButtonItem :btnText="t('deposit_now')" @click="clickDepositNow" />
      </div>
      <NociceView type="Deposit" />
    </div>
    <div v-if="walletStore.isLoading" class="fixed left-0 top-0 w-full h-full flex justify-center items-center">
      <vue3-lottie :animationData="loadingAniJson" :width="100" :height="100"></vue3-lottie>
    </div>
  </div>
</template>

<style scoped>
/* .deposit-container {
  .tab-selected {
    background-color: #222942;
    color: #ffffff;
    text-shadow:
      0px 0px 8px #1184fa,
      0px 0px 12px #1184fa;
  }
  .tab-unselected {
    background-color: transparent;
    color: #5b7bab;
  }
  .copy-btn {
    cursor: pointer;
    background-color: #222942;
    color: #fff;
    box-shadow:
      0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
      0px 0px 12px 0px #142034 inset;
  }
  .deposit-now-btn {
    cursor: pointer;
    background-color: #3aa1ff;
    color: #121829;
    box-shadow:
      0px 0px 10px 0px rgba(17, 132, 250, 0.4),
      0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
      0px 0px 12px 0px #0d52b2 inset;
  }
} */
</style>
