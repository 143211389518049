<template>
  <div class="root_div" @click="providerShow = false">
    <menus></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <my-header @walletChange="walletChangeEvent" @loginAfter="gameLoginAfter" @chooseWallet="chooseWallet" :showLogin="showLogin" :symbol="wallet.symbol" @hideShowLogin="hideShowLogin"></my-header>

      <div class="main-content web" style="line-height: 0">
        <div class="body width-100" ref="boxMain">
          <div :style="isFull ? fullStyleBox : styleBox" v-if="(gameUrl && topClass == 'web') || isFull" id="myFrameBox">
            <iframe id="myFrame" :style="isFull ? fullStyleGame : styleGame" ref="frame" :src="gameUrl" v-show="gameUrl && topClass == 'web'"></iframe>
          </div>
          <div style="display: block; width: 100%" v-show="thisGame.is_maintain">
            <div style="width: 100%; height: 28rem; justify-content: center; align-items: center; display: flex" v-show="profitList.length == 0">
              <div>
                <div style="display: flex; justify-content: center; align-items: center; opacity: 0.5">
                  <img src="../../assets/images/home/web_logo.svg" style="width: 13.6875rem; height: 4.375rem" />
                </div>
                <div class="game-maintain-text">
                  {{ t('game_is_maintain') }}
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%" class="h-[28rem]" v-if="!gameUrl && !thisGame.is_maintain">
            <div style="" class="game-detail-balance">
              <div class="display-center cursor">
                <span class="!text-[#5B7BAB]">{{ t('game_details_1_1_1') }}</span>
                <span @click="showBalance = !showBalance">{{ wallet.balance || '0.0000' }}</span>
                <img :src="wallet.icon_url" v-show="wallet.icon_url" class="token" @click="showBalance = !showBalance" />

                <img src="../../assets/images/home/unit-usdt.png" class="token" v-show="!wallet.icon_url" />
                <img src="../../assets/images/home/Frame 33764.svg" class="icon" @click="showBalance = !showBalance" />
              </div>
              <div class="wallet_div" v-show="showBalance">
                <div class="close-div" @click="showBalance = false" v-if="showBalance"></div>
                <div class="absolute z-[10] w-[250px]">
                  <div class="dia_balance !bg-[#191F33]">
                    <div class="dia_balance_item flex justify-between items-center text-[14px]" v-show="w.symbol !== 'Gold'" v-for="(w, index) in walletList" :key="index" @click="chooseWallet(w)">
                      <div class="flex justify-between items-center">
                        <img :src="w.icon_url" class="token" style="margin-right: 0.3rem" />
                        <span class="unit">{{ w.symbol }}</span>
                      </div>
                      <span class="text-[#8ea3c3]">{{ w.balance || '0.0000' }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="display-center desc break-keep">
                {{ t('while_playing___in') }}<span @click="profileSetting()" class="whitespace-nowrap overflow-hidden text-ellipsis">{{ t('profile_setting') }}</span>
              </div>
              <div class="display-center play_btn flex !justify-center">
                <div class="first text-[#D6E3F6] px-[1rem]" v-if="isShowFreePlayBtn" @click="getGameUrlServiceEvent('Demo')"><img class="mr-2" src="../../assets/images/game/game-play1.svg" />{{ t('free_play') }}</div>
                <div class="bg-theme shadow-custom-combined px-[1rem] text-[#0C131D]" @click="getGameUrlServiceEvent('OnlineReal')"><img class="mr-2" src="../../assets/images/game/game-play.svg" />{{ t('real_play') }}</div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div class="main-content web">
        <div class="body game-detail-bottom">
          <div class="game-detail-bottom flex justify-between">
            <div class="first-div">
              <div class="game-detail-bottom-left pl-6">
                <div class="text-3 font-bold text-[#fff]" style="">{{ thisGame.game_name }}</div>
                <div class="text-3 ml-2">{{ thisGame.game_provider ? thisGame.game_provider.name : '' }}</div>
              </div>
            </div>
            <div class="game-detail-right">
              <div class="flex-right">
                <wgSwitch
                  v-model="fun_play_checked"
                  class="mr-[0.5rem]"
                  :disabled="!isShowFreePlayBtn"
                  @change="
                    (value) => {
                      getGameUrlServiceEvent(value ? 'OnlineReal' : 'Demo');
                    }
                  "
                ></wgSwitch>
                {{ t('real_play') }}
                <div @click="gameFavorits(thisGame)" class="flex items-center ml-4">
                  <svg class="cursor-pointer" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M12 21.505L10.55 20.185C5.4 15.515 2 12.435 2 8.65503C2 5.57503 4.42 3.15503 7.5 3.15503C9.24 3.15503 10.91 3.96503 12 5.24503C13.09 3.96503 14.76 3.15503 16.5 3.15503C19.58 3.15503 22 5.57503 22 8.65503C22 12.435 18.6 15.515 13.45 20.195L12 21.505Z" :fill="thisGame.is_favorite ? '#F04167' : '#8EA3C3'" />
                  </svg>
                </div>
                <img src="../../assets/images/game/btn_game_4.svg" class="web" style="width: 1.5rem" @click="isFull = !isFull" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content phone">
        <div class="body profit margin-top-1rem">
          <div :style="styleBox" v-if="gameUrl && topClass == 'phone'">
            <iframe id="myFrame2" :style="styleGame" ref="frame2" :src="gameUrl" @load="handleIframeLoad"></iframe>
            <button v-if="showIframeClose" class="title-close" @click="clickCloseGame">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14" fill="none">
                <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
                <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
              </svg>
            </button>
          </div>
          <div style="display: block; width: calc(100% - 1.75rem); margin: 0.87rem 0; background: rgba(4, 13, 26, 0.94); position: absolute" v-show="thisGame.is_maintain">
            <div style="width: 100%; height: 13rem; justify-content: center; align-items: center; display: flex" v-show="profitList.length == 0">
              <div>
                <div style="display: flex; justify-content: center; align-items: center; opacity: 0.5">
                  <img src="../../assets/images/home/web_logo.svg" style="width: 13.6875rem; height: 4.375rem" />
                </div>
                <div class="game-maintain-text">
                  {{ t('game_is_maintain') }}
                </div>
              </div>
            </div>
          </div>
          <div style="width: calc(100%); display: flex; padding: 0.875rem 0">
            <div class="game-main-image">
              <img :src="thisGame.image || thisGame.game_image || thisGame.image_url" class="" />
            </div>
            <div class="phone game-title-bar-desc" style="width: 100%">
              <div class="game-title-bar-desc-name">
                <div class="!text-[#FFF] !font-bold !text-[0.75rem]" style="float: left; width: calc(100% - 2rem); color: var(--color-8ea3c3); font-family: 'Poppins', sans-serif; font-size: 0.875rem; font-weight: 600; letter-spacing: 0em; text-align: left">
                  {{ thisGame.game_name }}
                </div>
                <!-- <div style="float: right; width: 2rem">
                  <img src="../../assets/images/game/game-question.svg" style="width: 0.875rem" />
                </div> -->
              </div>
              <div class="desc text-[#AAC4ED] text-[0.75rem]" style="font-family: 'Poppins', sans-serif; font-weight: 600; letter-spacing: 0em; text-align: left">
                <!-- <span style="color: var(--color-55657E); margin-right: 1rem; text-decoration: none">By</span> -->
                <span>{{ thisGame.sheet_name }}</span>
              </div>
              <div class="mt-2" style="color: var(--color-8ea3c3); font-weight: 600">
                <svg @click="gameFavorits(thisGame)" class="cursor-pointer" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path d="M12 21.505L10.55 20.185C5.4 15.515 2 12.435 2 8.65503C2 5.57503 4.42 3.15503 7.5 3.15503C9.24 3.15503 10.91 3.96503 12 5.24503C13.09 3.96503 14.76 3.15503 16.5 3.15503C19.58 3.15503 22 5.57503 22 8.65503C22 12.435 18.6 15.515 13.45 20.195L12 21.505Z" :fill="thisGame.is_favorite ? '#F04167' : '#8EA3C3'" />
                </svg>
              </div>
            </div>
          </div>

          <div style="clear: both"></div>
          <div class="display-center play_btn bottom-1rem" v-show="!thisGame.is_maintain">
            <div class="first text-[#D6E3F6] w-[47.5%]"  v-if="isShowFreePlayBtn" @click="getGameUrlServiceEvent('Demo')"><img class="mr-2" src="../../assets/images/game/game-play1.svg" />{{ t('free_play') }}</div>
            <div class="bg-theme shadow-custom-combined w-[47.5%] text-[#0C131D]" @click="getGameUrlServiceEvent('OnlineReal')"><img class="mr-2" src="../../assets/images/game/game-play.svg" />{{ t('real_play') }}</div>
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="main-content web">
        <div class="bottom-1rem"></div>
      </div>
      <div class="main-content">
        <div class="body width-100 profit">
          <div class="flex justify-end mr-2">
            <!-- <div class="winner-bar-title" style="">{{ thisGame.game_name }}</div>
            <div class="winner-bar-title second">{{ thisGame.game_provider ? thisGame.game_provider.name : '' }}</div> -->
            <div class="winner-bar-title right !h-[3rem]">
              <span class="text-base mr-2"> {{ t('big_winner') }}</span>
              <div class="winner-bar-title-right bg-[#000000]" v-if="maxLuckyWinner.rate">
                <img src="../../assets/images/referral/referral-gold.png" />
                <span style="padding: 0 0.5rem">{{ maxLuckyWinner.rate }}x</span>
                <span class="white" :title="maxLuckyWinner.user_name">{{ maxLuckyWinner.user_name }}</span>
              </div>
              <img v-if="thisGame.open" @click="thisGame.open = !thisGame.open" src="../../assets/images/home/Frame 33764.svg" style="float: right" />
              <img v-if="!thisGame.open" @click="thisGame.open = !thisGame.open" class="img" src="../../assets/images/home/Frame 33764.svg" style="float: right" />
            </div>
          </div>
          <div style="clear: both"></div>
          <div style="width: 95%; margin-left: 2.5%" v-show="thisGame.open">
            <div class="body tool-bar tool-bar-search bottom-1rem body2">
              <div class="tool-bar-tabs winner-list-tabs">
                <div :class="'!px-[0.5rem] md:!px-[1rem] ' + (winnerType == 'big_winner' ? 'first item selected' : 'first item ')" @click="winnerTypeChange('big_winner')">
                  {{ t('big_winner') }}
                </div>
                <div :class="'!px-[0.5rem] md:!px-[1rem] ' + (winnerType == 'lucky_winner' ? 'item selected' : 'item ')" @click="winnerTypeChange('lucky_winner')">
                  {{ t('lucky_winner') }}
                </div>
                <div :class="'!px-[0.5rem] md:!px-[1rem] ' + (winnerType == 'desc' ? 'last item selected' : 'last item ')" @click="winnerTypeChange('desc')">
                  {{ t('desc') }}
                </div>
              </div>
            </div>
            <div style="width: 100%; display: flex" v-show="winnerType == 'desc'">
              <div style="float: left; cursor: pointer" class="game-main-image">
                <img :src="thisGame.game_image" class="" />
              </div>
              <div class="game-title-bar-desc" style="">
                <div class="game-title-bar-desc-name">{{ thisGame.game_name }}</div>
                <div class="desc">{{ thisGame.game_type }}</div>
                <div class="rtp">{{ t('house_edge') }} {{ thisGame.rtp || '2%' }}</div>
              </div>
              <div style="clear: both" class="bottom-1rem"></div>
            </div>
            <winner-list :isMainClass="false" v-show="winnerType == 'big_winner'" :profitList="bigWinnerList" :isAll="indexProfit == 'all'" :show-rank="true" :walletList="tokenIconList"></winner-list>
            <winner-list :isMainClass="false" v-show="winnerType == 'lucky_winner'" :profitList="luckyWinnerList" :isAll="indexProfit == 'all'" :show-rank="true" :walletList="tokenIconList"></winner-list>
          </div>
        </div>
      </div>
      <my-game-list :gameList="gameList" :game="game" @next="next()"></my-game-list>
      <my-game-support></my-game-support>
      <!-- <div class="main-content"> -->
      <bets-list @parentLogin="parentLogin"></bets-list>
      <!-- </div> -->

      <div class="main-content">
        <my-testimonials-list :gameList="testimonialsList" :game="testimonials"></my-testimonials-list>
        <!--        <my-game-list :gameList="testimonialsList" :game="testimonials"></my-game-list>-->
      </div>
      <my-footer :isHomeFooter="true"></my-footer>
    </div>
  </div>
</template>
<script setup>
import menus from '../menus/myMenus.vue';
import myHeader from '../../components/myHeader.vue';
import myFooter from '@/components/footer/footer.vue';
import myGameList from '@/components/game/gameList.vue';
import betsList from '@/components/profit/betsList.vue';
import myTestimonialsList from '@/components/testimonials/testimonialsList.vue';
import winnerList from '@/components/profit/winnerList.vue';
import { useI18n } from 'vue-i18n';
// import myLogin from "@/pages/login/myLogin.vue";

const { t } = useI18n();
</script>
<script>
// import icon from "@/assets/images/footer/footer-token-1.png";
import providerListImg1 from '@/assets/images/home/provider-pp 1.png';
import providerListImg2 from '@/assets/images/home/provider-pp 2.png';
import providerListImg3 from '@/assets/images/home/provider-pp 3.png';
import providerListImg4 from '@/assets/images/home/provider-pp 4.png';
import providerListImg5 from '@/assets/images/home/provider-pp 5.png';
import testimonialsImg1 from '@/assets/images/home/money 2.png';
import testimonialsLevelImg from '@/assets/images/home/ico_b1.png';
import testimonialsImg2 from '@/assets/images/home/money 3.png';
import testimonialsImg3 from '@/assets/images/home/money 4.png';
import testimonialsImg4 from '@/assets/images/home/money 1.png';
import in_house_gameImg2 from '../../assets/images/home/btn_8_2.svg';
import gameRecommendImg from '@/assets/images/new-version/menu/Slots-1.svg';
import serverConfig from '@/assets/network/serverConfig';
import { favoriteGameService, getBigWinner, getGameUrlService, getLuckyWinner, openboxSearchGames, unfavoriteGameService } from '@/assets/network/service/game';
import { getGameInfo, setGameInfo, getCookiesByKey, setCookiesByKey } from '@/assets/tools/CommonTool';
import { toast } from 'vue3-toastify';
import { getTokens } from '@/assets/network/service/wallet.js';
import { gaSelectContentEvent } from '@/assets/tools/GoogleAnalyticsEvent';
import { useLoginStore } from '@/store/store';
import wgSwitch from '@/components/common/wgSwitch.vue';
export default {
  name: 'gameDetails',
  props: {},
  components: {
    // myI18n
  },
  errorCaptured(err, vm, info) {
    this.error = `${err.stack}\n\n在组件的 ${info} 中发现错误`;
    console.log(this.error);
    return false;
  },
  watch: {
    isFull: function (newVal) {
      this.isFull = newVal;
      if (this.thisGame.is_maintain) {
        return;
      }
      this.calcFullStyle(newVal);
    }
  },
  data() {
    return {
      showBalance: false,
      showLogin: false,
      thisGame2: {},
      thisGame: {},
      fun_play_checked: true,
      providerShow: false,
      providerShow2: false,
      providerSearchList: [
        {
          isChecked: false,
          name: 'Pragmatic Play',
          code: 'PragmaticPlay',
          number: 0
        },
        {
          isChecked: false,
          name: 'PG Soft',
          code: 'PGSoft',
          number: 0
        }
      ],
      isGameSearch: false,
      searchResult: [],
      searchResult2: [],
      testimonialsList: [
        {
          image: testimonialsImg1,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg2,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg3,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg4,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        }
      ],
      providerList: [
        {
          image: providerListImg1
        },
        {
          image: providerListImg2
        },
        {
          image: providerListImg3
        },
        {
          image: providerListImg4
        },
        {
          image: providerListImg5
        }
      ],
      gameSupport: {
        gameCode: 'game_support',
        gameImage: in_house_gameImg2,
        showBottom: false
      },
      testimonials: {
        gameCode: 'testimonials',
        showBottom: true
      },
      profitList: [],
      game: {
        gameCode: 'Recommended',
        gameImage: gameRecommendImg,
        showBottom: true
      },
      gameList: [],
      gameIndex: 0,
      maxGameIndex: 0,
      pageGameList: [],
      allSize: 0,
      setupSize: 0,
      indexProfit: 'me',
      winnerType: 'big_winner',
      searchText: '',
      total: 0,
      searchParams: {
        game_type: 'CasinoSlot',
        name: ' ',
        start: 0,
        end: 20,
        game_provider_id: ''
      },
      pageIndex: 0,
      wallet: {},
      cookieGame: {},
      is_favoriting: false,
      gameUrl: '',
      isLogin: false,
      bigWinnerList: [],
      luckyWinnerList: [],
      maxLuckyWinner: {},
      boxWidth: '',
      styleBox: '',
      styleGame: '',
      fullStyleBox: '',
      fullStyleGame: '',
      isFull: false,
      topClass: 'web',
      id: '',
      showIframeClose: false,
      tokenIconList: [],
      isShowFreePlayBtn: false
    };
  },
  created() {
    document.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
    document.addEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
    document.addEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
    document.addEventListener('MSFullscreenChange', this.fullScreenChangeHandler);
    this.init();
    window.addEventListener('popstate', this.backEvent);
  },
  methods: {
    openLogin() {
      const loginStore = useLoginStore();
      loginStore.updateShow(true);
      loginStore.updateActType('login');
    },
    backEvent(event) {
      let page_size = getCookiesByKey('games_page_size');
      if (page_size) {
        setCookiesByKey('new_games_page_size', page_size);
      }
      let top_px = getCookiesByKey('top_px');
      if (top_px) {
        setCookiesByKey('new_top_px', top_px);
      }
      console.log(page_size);
      console.log(getCookiesByKey('new_games_page_size'));
      // 组件销毁时移除事件监听器
      setCookiesByKey('games_page_size', '');
      setCookiesByKey('top_px', '');
      // 处理返回事件
      console.log('浏览器返回事件触发');
      console.log(event.state); // 获取状态信息
    },
    getTokens() {
      getTokens().then((response) => {
        this.tokenIconList = response.data.data;
      });
    },
    handleIframeLoad() {
      this.showIframeClose = true;
    },
    clickCloseGame() {
      console.log('关闭游戏')
      this.gameUrl = '';
      this.showIframeClose = false;
    },
    fullScreenChangeHandler() {
      if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
        // 进入全屏模式
        console.log('Changed to fullscreen');
        this.isFull = true;
      } else {
        console.log('Changed to exitFullScreen');
        this.isFull = false;
      }
    },
    calcFullStyle(isFull) {
      let myFrameBox = document.getElementById('myFrameBox');
      if (isFull) {
        let height = window.innerHeight - 124;
        if (height < 500) {
          height = 500;
        }
        this.fullStyleBox = '';
        this.fullStyleGame = 'border-Radius:0.5rem 0.5rem 0 0;border:0;' + 'height:' + height + 'px);width: ' + height / 0.512 + 'px;';
        this.requestFullScreen(myFrameBox);
      } else {
        this.fullStyleBox = '';
        this.fullStyleGame = '';
        // this.cancelFullScreen()
      }
    },
    // 退出全屏模式
    cancelFullScreen(el) {
      var requestMethod = el.exitFullscreen || el.webkitExitFullscreen || el.mozCancelFullScreen || el.msExitFullscreen;
      if (requestMethod) {
        requestMethod.call(el);
      }
    },
    requestFullScreen(element) {
      var requestMethod = element.requestFullscreen || element.webkitRequestFullscreen || element.mozRequestFullScreen || element.msRequestFullscreen;
      if (requestMethod) {
        requestMethod.call(element);
        this.fullStyleBox = 'position: fixed;top:0;left:0;width: 100vw;height: ' + window.innerHeight + 'px;z-index: 99999;display:block;';
        this.fullStyleGame = 'border:0;width: 100%;height: 100%;';
      }
    },
    init() {
      this.getTokens();
      let game = getGameInfo();
      this.cookieGame = JSON.parse(game);
      this.id = this.$route.query.id || this.cookieGame.game_uid;
      if (this.id) {
        openboxSearchGames({ game_uid: this.id })
          .then((response) => {
            if (response.data.data.length > 0) {
              let game = response.data.data[0];
              if (game) {
                this.cookieGame = game;
                for (let key in this.cookieGame) {
                  this.thisGame[key] = this.cookieGame[key];
                  console.log(this.cookieGame[key]);
                }
                setGameInfo(JSON.stringify(this.cookieGame));
                this.queryWinners();
                this.isShowFreePlayBtn = game.has_demo_mode;
                gaSelectContentEvent({ type: game.game_type, name: game.game_name });
              } else {
                return;
              }
            }
          })
          .catch((error) => {
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if (error.response.status === 500) {
              errorMsg = error.message;
            }
            toast(errorMsg, { autoClose: 2000, type: 'error' });
          });
      } else {
        this.gameList = [];
        if (game) {
          this.cookieGame = JSON.parse(game);
          for (let key in this.cookieGame) {
            this.thisGame[key] = this.cookieGame[key];
            console.log(this.cookieGame[key]);
          }
          this.queryWinners();
        } else {
          return;
        }
      }
      this.openboxSearchGames();
      window.addEventListener('resize', this.resize);
    },
    parentLogin() {
      this.openLogin();
      // this.showLogin = true;
      this.$emit('parentLogin');
    },
    resize() {
      if (this.$refs.boxMain) {
        this.boxWidth = this.$refs.boxMain.offsetWidth;
      }

      this.topClass = this.$isMobile() ? 'phone' : 'web';
      if (this.topClass == 'phone') {
        this.topClass = 'phone';
      } else if (window.innerWidth <= serverConfig.breakPx[1]) {
        this.topClass = 'phone';
      } else if (window.innerWidth <= serverConfig.breakPx[0]) {
        this.topClass = 'web';
      }
      if (this.topClass == 'phone') {
        this.styleBox = 'position: fixed;top:0;left:0;width: 100vw;height: ' + window.innerHeight + 'px;z-index: 99999;';
        this.styleGame = 'border:0;width: 100%;height: 100%;';
      } else {
        this.styleBox = 'width:100%;';
        this.styleGame = 'border-Radius:0.5rem 0.5rem 0 0;border:0;width: 100%;' + 'height:' + this.boxWidth * 0.512 + 'px;';
      }
    },
    queryWinners() {
      let game_api_provider = this.thisGame.game_api_provider;
      game_api_provider = game_api_provider.toLowerCase();
      getBigWinner({
        game_developer: game_api_provider,
        game_uid: this.thisGame.game_uid
      })
        .then((response) => {
          this.bigWinnerList = response.data.data;
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
      getLuckyWinner({
        game_developer: game_api_provider,
        game_uid: this.thisGame.game_uid
      })
        .then((response) => {
          this.luckyWinnerList = response.data.data;
          if (this.luckyWinnerList.length > 0) {
            this.maxLuckyWinner = this.luckyWinnerList[0];
          }
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    jump(path) {
      if (!this.isLogin) {
        // this.showLogin = true;
        this.openLogin();
        return;
      }
      this.$router.push(path);
    },
    replaceBackUrl(originalUrl, newBackUrl) {
      // 创建一个 URL 对象
      let url = new URL(originalUrl);

      // 将新 backUrl 参数值进行 URL 编码
      let encodedNewBackUrl = encodeURIComponent(newBackUrl);

      // 更新 URL 对象中的 backUrl 参数
      url.searchParams.set('backUrl', encodedNewBackUrl);

      // 返回新的 URL 字符串
      return url.toString();
    },

    getGameUrlServiceEvent(playDemo) {
      console.log(playDemo);
      if (this.thisGame.is_maintain) {
        toast(this.$t('game_is_maintain'), 2000);
        return;
      }
      if (!this.isLogin) {
        // this.showLogin = true;
        this.openLogin();
        return;
      }
      getGameUrlService({
        game_uid: this.thisGame.game_uid,
        play_mode: playDemo,
        language: this.$i18n.locale,
        country: 'BR',
        currency: this.wallet.symbol
      })
        .then((response) => {
          this.boxWidth = this.$refs.boxMain.offsetWidth;
          this.resize();

          this.gameUrl = this.replaceBackUrl(response.data.gameUrl, 'https://www.google.com');
          if (playDemo == 'Demo') {
            this.fun_play_checked = false;
          } else {
            this.fun_play_checked = true;
          }
        })
        .catch((error) => {
          const msg = error.response && error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (!errorMsg) {
            errorMsg = error.response.data.data.error_msg;
          }
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    gameLoginAfter(walletList) {
      this.walletList = walletList;
      this.isLogin = true;
      this.showLogin = false;
    },
    walletChangeEvent(wallet) {
      this.wallet = wallet;
    },
    next() {
      this.pageIndex++;
      this.openboxSearchGames();
    },
    openboxSearchGames() {
      this.searchParams.start = this.pageIndex * 20;
      this.searchParams.end = this.searchParams.start + 20;
      openboxSearchGames(this.searchParams)
        .then((response) => {
          this.gameList = response.data.data;
          this.total = response.data.total_count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideShowLogin() {
      this.showLogin = false;
    },
    gameFavorits(thisGame) {
      if (this.is_favoriting) {
        return;
      }
      if (!this.isLogin) {
        // this.showLogin = true;
        this.openLogin();
        return;
      }
      this.is_favoriting = true;
      if (thisGame.is_favorite) {
        // 取消收藏
        unfavoriteGameService({ game_uid: thisGame.game_uid })
          .then((response) => {
            toast(this.$t('un_favorite_successful'), { autoClose: 2000, type: 'success' });
            thisGame.is_favorite = !thisGame.is_favorite;
            thisGame.favorite_count = response.data.total_count;
            this.cookieGame.is_favorite = thisGame.is_favorite;
            this.cookieGame.favorite_count = response.data.total_count;
            setGameInfo(JSON.stringify(this.cookieGame));
            this.is_favoriting = false;
          })
          .catch((error) => {
            this.is_favoriting = false;
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if (error.response.status === 500) {
              errorMsg = error.message;
            }
            toast(errorMsg, { autoClose: 2000, type: 'error' });
          });
      } else {
        // 收藏游戲
        favoriteGameService({ game_uid: thisGame.game_uid })
          .then((response) => {
            toast(this.$t('favorite_successful'), { autoClose: 2000, type: 'success' });
            thisGame.is_favorite = !thisGame.is_favorite;
            this.cookieGame.is_favorite = thisGame.is_favorite;
            thisGame.favorite_count = response.data.total_count;
            this.cookieGame.favorite_count = response.data.total_count;
            setGameInfo(JSON.stringify(this.cookieGame));
            this.is_favoriting = false;
          })
          .catch((error) => {
            this.is_favoriting = false;
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if (error.response.status === 500) {
              errorMsg = error.message;
            }
            toast(errorMsg, { autoClose: 2000, type: 'error' });
          });
      }
    },
    winnerTypeChange(winnerType) {
      this.winnerType = winnerType;
    },
    profitChange(indexProfit) {
      this.indexProfit = indexProfit;
    },
    enterSearch() {
      console.log(this.searchText);
      // let searchResult = JSON.parse(JSON.stringify(this.searchResult))
      // console.log(searchResult)
      this.searchResult = [];
      for (let i = 0; i < this.searchResult2.length && i < 6; i++) {
        this.searchResult.push(this.searchResult2[i]);
      }
    },
    searchBlur() {},
    searchFocus() {
      this.isGameSearch = true;
    },
    profileSetting() {
      if (!this.isLogin) {
        // this.showLogin = true;
        this.openLogin();
        return;
      }
      this.$router.push('/profile/setting');
    },
    chooseWallet(w) {
      if (this.wallet.symbol != w.symbol) {
        this.gameUrl = '';
      }
      this.wallet = w;
      this.showBalance = false;
    }
  }
};
</script>
<style scoped>
::v-deep .slots .game-main {
  max-width: 12.5%;
  margin-right: 1.4% !important;
}
::v-deep .web .game-list-content .games-list .game-main .game-main-image img {
  width: 100% !important;
  max-width: 100% !important;
}
.game-main-image img {
  max-width: 9.625rem;
}
.tool-bar .tool-bar-tabs div {
  height: 2.5rem;
}
.web .tool-bar .tool-bar-tabs {
  width: auto;
}
.tool-bar .tool-bar-tabs div.first {
  margin-left: 0;
}
.tool-bar .tool-bar-tabs div.last {
  margin-right: 0;
}
.game-detail-bottom {
  display: flex;
  white-space: nowrap;
}
.game-detail-center {
  display: flex;
  height: 4rem;
  align-items: center;
  width: 70%;
  justify-content: center;
}
.game-detail-center .icon {
  height: 1.81rem;
  width: 5.75rem;
}

.game-detail-right {
  color: var(--color-8ea3c3);
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 30%;
  padding-right: 1rem;
}

.winner-bar-title-out .right {
  width: 90%;
  justify-content: flex-end;
}
.game-detail-balance {
  width: 31rem;
  text-align: center;
  margin: auto;
}
.token {
  width: 1rem;
  height: 1rem;
  padding: 0 0 0 0.5rem;
}
.game-detail-balance .icon {
  width: 0.5rem;
  height: 0.5rem;
  padding: 0 0 0 0.5rem;
}
.flex-right {
  display: flex;
  align-items: center;
  justify-content: right;
}
.game-maintain-text {
  font-weight: 500;
  line-height: normal;
  font-size: 0.9375rem;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  margin-top: 1rem;
}
.phone .main-content .body {
  margin: 0 0.5rem;
  width: calc(100% - 2rem);
}
.phone .main-content .body.body2 {
  margin: 0;
  width: calc(100%);
}
.wallet_div {
  display: flex;
  line-height: 2.5rem;
  justify-content: center;
}
.dia_balance {
  background-color: var(--deposit-withdraw-selected-div-bg-color);
  border-radius: 0.5rem;
  font-weight: 700;
  max-height: 25rem;
  overflow-y: auto;
}
.dia_balance::-webkit-scrollbar {
  border-radius: 0.5rem;
}
.dia_balance::-webkit-scrollbar-track {
  border-radius: 0.5rem;
}
.dia_balance::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
}
.dia_balance_item.first {
  border-radius: 0.5rem 0.5rem 0 0;
}
.dia_balance_item.last {
  border-radius: 0 0 0.5rem 0.5rem;
}
.dia_balance_item {
  padding: 0 0.5rem;
}
.dia_balance_item:hover {
  background-color: var(--color-8ea3c3);
}
.title-close {
  background-color: #55657e;
  cursor: pointer;
  border: none;
  padding: 9px;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
</style>
