<template>
  <div class="root_div web">
    <menus></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <my-header></my-header>
      <div class="main-content">
        <div class="body width-100">
          <div class="text-[18px] text-[#ffffff] font-[700] text-left">{{ t('help_center') }}</div>
          <div class="help_content">
            <div class="left">
              <div @click="defaultHelpIndex = index" v-for="(help, index) in helpConfig" :key="index" :class="'item' + (defaultHelpIndex == index ? ' selected' : '')">
                {{ t(help.code) }}
              </div>
            </div>
            <div class="right">
              <div v-for="(help, index) in helpConfig" :key="index" v-show="defaultHelpIndex == index" class="flex flex-col gap-[20px]">
                <div v-for="(children, index2) in help.children" :key="index2" class="flex flex-col gap-[15px]">
                  <div class="item">{{ t(children.title).toUpperCase() }}</div>
                  <ul class="flex flex-col gap-[10px] m-0 pl-[20px]">
                    <template v-if="children.dot">
                      <li v-for="(content, c_index) in children.content" :key="c_index" class="children-item">{{ t(content) }}</li>
                    </template>
                    <template v-else>
                      <div v-for="(content, c_index) in children.content" :key="c_index" class="children-item">{{ t(content) }}</div>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <my-footer :isHomeFooter="true"></my-footer>
    </div>
  </div>
  <div class="phone main-content" style="padding-top: 0; min-height: 100vh">
    <div class="width-100 phone-top-title">
      <div style="display: flex; align-items: center; width: 80%; padding-left: 0.5rem; color: var(--color-8ea3c3); font-size: 1.125rem; font-weight: 400">
        <img src="../../assets/images/home/btn_top_jd.svg" style="transform: rotate(180deg); height: 1rem; margin-right: 0.5rem" @click="back()" />
        {{ t('help_center') }}
      </div>
    </div>
    <div class="phone_help">
      <div class="phone-top pt-[20px] pb-[20px]">
        <el-select v-model="defaultHelpIndex" class="help-select" :placeholder="t('Please select')" size="large" :teleported="false">
          <el-option v-for="(item, index) in helpConfig" :key="index" :label="t(item.code)" :value="index" />
        </el-select>
      </div>
      <div class="phone-bottom">
        <div v-for="(help, index) in helpConfig" :key="index" v-show="defaultHelpIndex == index" class="flex flex-col gap-[20px]">
          <div v-for="(children, index2) in help.children" :key="index2" class="flex flex-col gap-[15px]">
            <div class="item">{{ children.content.length > 0 ? t(children.title) : t(children.title).toUpperCase() }}</div>
            <ul class="flex flex-col gap-[10px] m-0 pl-[20px]">
              <template v-if="children.dot">
                <li v-for="(content, c_index) in children.content" :key="c_index" class="children-item">{{ t(content) }}</li>
              </template>
              <template v-else>
                <div v-for="(content, c_index) in children.content" :key="c_index" class="children-item">{{ t(content) }}</div>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.item-one {
  padding: 0 1.5rem;
  width: calc(100% - 3rem);
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  line-height: 2.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  margin: 0.5rem 0;
  color: var(--color-8ea3c3);
}
.item-one img {
  float: right;
}
.item-one.selected {
  color: var(--white-color);
  background-color: var(--help_content_phone_menu-bg-color);
}
.item-one.selected img {
  display: none;
}
.item-one:hover {
  color: var(--white-color);
  background-color: var(--tab-bg-color);
}
.item-one:hover img {
  display: none;
}
.phone_help {
  padding: 0 1.5rem;
  text-align: left;
}
.phone_help .item {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 115%;
  text-align: left;
}
.phone_help .children-item {
  color: #aac4ed;
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
  text-align: left;
  white-space: pre-line;
}
.help_title {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 4.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-8ea3c3);
  justify-content: left;
}
.help_title img {
  width: 1.6rem;
  height: 1.3rem;
  margin-right: 0.5rem;
}
.help_content {
  display: flex;
  width: 100%;
  gap: 30px;
}
.web .help_content .left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.web .help_content .left .item {
  box-sizing: border-box;
  width: 170px;
  min-height: 36px;
  padding: 6px 16px;
  border-radius: 8px;
  background-color: #222942;
  color: #5b7bab;
  display: flex;
  justify-content: center;
  align-items: center;
}
.web .help_content .left .item.selected {
  background-color: #3e4b79;
  color: #cee2ff;
}
.web .help_content .left .item:hover {
  background-color: #3e4b79;
  color: #cee2ff;
  cursor: pointer;
}
.web .help_content .right {
  width: calc(100% - 170px);
  border-radius: 8px;
  background-color: #191f33;
  padding: 20px;
}
.web .help_content .right .item {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 115%;
  text-align: left;
}
.web .help_content .right .children-item {
  color: #aac4ed;
  font-size: 15px;
  font-weight: 400;
  line-height: 125%;
  text-align: left;
  white-space: pre-line;
}
.phone .help_content .left {
  width: 100%;
}
.phone .help_content .right {
  width: 100%;
}
</style>
<script setup>
import menus from '@/pages/menus/myMenus.vue';
import myHeader from '@/components/myHeader.vue';
import myFooter from '@/components/footer/footer.vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

// const term_of_service_page_title = ref('term_of_service')
</script>
<script>
export default {
  name: 'App',
  props: {
    game: {},
    gameList: []
  },
  components: {
    // myI18n
  },
  data() {
    return {
      defaultHelpIndex: 0,
      phoneDefaultHelpIndex2: 0,
      helpCenter: {
        code: 'help_center',
        children: [
          {
            level: 1,
            code: 'what_is___amount',
            children: [
              {
                level: 2,
                code: 'what_is___amount',
                contextCode: 'the_withdrawable___balance'
              },
              {
                level: 2,
                code: 'why_is___amount',
                contextCode: 'in_order___secure'
              },
              {
                level: 2,
                code: 'how_to___amount',
                contextCode: 'you_can___amount'
              },
              {
                level: 2,
                code: 'why_will___reduced',
                contextCode: 'where_may___deducted',
                number: 2
              }
            ]
          },
          {
            level: 1,
            code: 'what_is__fee',
            children: [
              {
                level: 2,
                code: 'why_does___fee',
                contextCode: 'when_wen___mining',
                number: 2
              },
              {
                level: 2,
                code: 'the_role___fee',
                contextCode: 'encourage_miners___transaction',
                number: 1
              },
              {
                level: 2,
                code: 'how_much___fee',
                contextCode: 'since_the___platform'
              }
            ]
          },
          {
            level: 1,
            code: 'responsible_gaming',
            children: [
              {
                level: 2,
                code: 'responsible_gaming',
                contextCode: 'wingamegame_is___form',
                number: 4
              },
              {
                level: 2,
                code: 'the_restricted___are',
                contextCode: 'australia_aruba___possessions'
              },
              {
                level: 2,
                code: 'maintaining_control',
                contextCode: 'gambling_should___organisations',
                number: 22
              },
              {
                level: 2,
                code: 'self_exclusion',
                contextCode: 'should_you___considered',
                number: 3
              },
              {
                level: 2,
                code: 'gambling_counselling___organisations',
                contextCode: 'gamcare_the___org',
                number: 6
              },
              {
                level: 2,
                code: 'filtering_systems',
                contextCode: 'filtering_solutions___com',
                number: 2
              }
            ]
          },
          {
            level: 1,
            code: 'term_of_service',
            children: [
              {
                level: 2,
                code: 'introduction',
                contextCode: 'this_user___services'
              },
              {
                level: 2,
                code: 'general_terms',
                contextCode: 'we_reserve___agreement'
              },
              {
                level: 2,
                code: 'your_obligations',
                contextCode: 'during_your___services'
              },
              {
                level: 2,
                code: 'restricted_use',
                contextCode: 'you_may___wingame'
              },
              {
                level: 2,
                code: 'registration',
                contextCode: 'we_reserve___account'
              },
              {
                level: 2,
                code: 'your_account',
                contextCode: 'multiple_currencies___english'
              },
              {
                level: 2,
                code: 'inactive_accounts',
                contextCode: 'wingame_will___platform'
              },
              {
                level: 2,
                code: 'fund_deposits',
                contextCode: 'each_currency___deposits'
              },
              {
                level: 2,
                code: 'fund_withdrawals',
                contextCode: 'due_to___appeal'
              },
              {
                level: 2,
                code: 'payment_transactions___processors',
                contextCode: 'you_are___authorities'
              },
              {
                level: 2,
                code: 'errors',
                contextCode: 'if_there___frame'
              },
              {
                level: 2,
                code: 'sports_betting___cancellations',
                contextCode: 'the_winner___rules'
              },
              {
                level: 2,
                code: 'communication_and_notices',
                contextCode: 'you_must___settings'
              },
              {
                level: 2,
                code: 'force_majeure',
                contextCode: 'should_we___liability'
              },
              {
                level: 2,
                code: 'liability',
                contextCode: 'within_the___software'
              },
              {
                level: 2,
                code: 'underage_gambling',
                contextCode: 'if_we___agencies'
              },
              {
                level: 2,
                code: 'anti_fraud_policy',
                contextCode: 'wingame_prohibits___actions'
              },
              {
                level: 2,
                code: 'intellectual_property',
                contextCode: 'unauthorized_use___prosecution'
              },
              {
                level: 2,
                code: 'your_license',
                contextCode: 'subject_to___proceedings'
              },
              {
                level: 2,
                code: 'your_conduct___security',
                contextCode: 'for_the___receive'
              },
              {
                level: 2,
                code: 'links_to___websites',
                contextCode: 'the_service___use'
              },
              {
                level: 2,
                code: 'complaints',
                contextCode: 'if_you___provider'
              },
              {
                level: 2,
                code: 'assignment',
                contextCode: 'you_may___service'
              },
              {
                level: 2,
                code: 'severability',
                contextCode: 'if_any___affected'
              },
              {
                level: 2,
                code: 'breach_of___terms',
                contextCode: 'without_limiting___service'
              },
              {
                level: 2,
                code: 'general_provisions',
                contextCode: 'duration_of___written'
              }
            ]
          }
        ]
      },
      helpConfig: [
        {
          code: 'Privacy_Policy',
          children: [
            {
              title: 'win_game_privacy_policy',
              content: [],
              dot: false
            },
            {
              title: 'win_game_privacy_policy_title_1',
              content: ['win_game_privacy_policy_1_1', 'win_game_privacy_policy_1_2', 'win_game_privacy_policy_1_3'],
              dot: true
            },
            {
              title: 'win_game_privacy_policy_title_2',
              content: [
                'win_game_privacy_policy_2_1',
                'win_game_privacy_policy_2_1_1',
                'win_game_privacy_policy_2_1_2',
                'win_game_privacy_policy_2_1_3',
                'win_game_privacy_policy_2_1_4',
                'win_game_privacy_policy_2_1_5',
                'win_game_privacy_policy_2_1_6',
                'win_game_privacy_policy_2_2',
                'win_game_privacy_policy_2_2_1',
                'win_game_privacy_policy_2_2_2',
                'win_game_privacy_policy_2_2_3',
                'win_game_privacy_policy_2_2_3_1'
              ],
              dot: true
            },
            {
              title: 'win_game_privacy_policy_title_3',
              content: ['win_game_privacy_policy_3_1', 'win_game_privacy_policy_3_1_1', 'win_game_privacy_policy_3_1_2', 'win_game_privacy_policy_3_1_3', 'win_game_privacy_policy_3_1_4', 'win_game_privacy_policy_3_1_5', 'win_game_privacy_policy_3_1_6', 'win_game_privacy_policy_3_1_7', 'win_game_privacy_policy_3_2'],
              dot: true
            },

            {
              title: 'win_game_privacy_policy_title_4',
              content: ['win_game_privacy_policy_4_1', 'win_game_privacy_policy_4_1_1', 'win_game_privacy_policy_4_1_2', 'win_game_privacy_policy_4_1_3', 'win_game_privacy_policy_4_1_4', 'win_game_privacy_policy_4_1_5', 'win_game_privacy_policy_4_1_6'],
              dot: true
            },
            {
              title: 'win_game_privacy_policy_title_5',
              content: ['win_game_privacy_policy_5_1'],
              dot: true
            },
            {
              title: 'win_game_privacy_policy_title_6',
              content: ['win_game_privacy_policy_6_1', 'win_game_privacy_policy_6_1_1', 'win_game_privacy_policy_6_1_2', 'win_game_privacy_policy_6_1_3', 'win_game_privacy_policy_6_1_4', 'win_game_privacy_policy_6_2'],
              dot: true
            },
            {
              title: 'win_game_privacy_policy_title_7',
              content: ['win_game_privacy_policy_7_1', 'win_game_privacy_policy_7_1_1', 'win_game_privacy_policy_7_1_2', 'win_game_privacy_policy_7_1_3', 'win_game_privacy_policy_7_1_4', 'win_game_privacy_policy_7_1_5', 'win_game_privacy_policy_7_1_6', 'win_game_privacy_policy_7_1_6_1'],
              dot: true
            },
            {
              title: 'win_game_privacy_policy_title_8',
              content: ['win_game_privacy_policy_8_1', 'win_game_privacy_policy_8_2', 'win_game_privacy_policy_8_3'],
              dot: true
            },
            {
              title: 'win_game_privacy_policy_title_9',
              content: ['win_game_privacy_policy_9_1', 'win_game_privacy_policy_9_2'],
              dot: true
            },
            {
              title: 'win_game_privacy_policy_title_10',
              content: ['win_game_privacy_policy_10_1'],
              dot: false
            }
          ]
        },
        {
          code: 'Term_of_Service',
          children: [
            {
              title: 'Term_of_Service',
              content: [],
              dot: false
            },
            {
              title: 'term_of_service_title_1',
              content: ['term_of_service_1_1', 'term_of_service_1_2'],
              dot: false
            },
            {
              title: 'term_of_service_title_2',
              content: ['term_of_service_2_1'],
              dot: false
            },
            {
              title: 'term_of_service_title_3',
              content: [
                'term_of_service_title_3_1',
                'term_of_service_3_1',
                'term_of_service_3_2',
                'term_of_service_3_2',
                'term_of_service_3_3',
                'term_of_service_3_3_1',
                'term_of_service_3_4',
                'term_of_service_3_5',
                'term_of_service_3_6',
                'term_of_service_3_7',
                'term_of_service_3_8',
                'term_of_service_3_9',
                'term_of_service_3_10',
                'term_of_service_3_11',
                'term_of_service_3_12',
                'term_of_service_3_13',
                'term_of_service_3_14',
                'term_of_service_3_15',
                'term_of_service_3_16',
                'term_of_service_3_17',
                'term_of_service_3_17_1',
                'term_of_service_3_18',
                'term_of_service_3_19'
              ],
              dot: true
            },
            {
              title: 'term_of_service_title_4',
              content: [
                'term_of_service_4_1',
                'term_of_service_4_1_1',
                'term_of_service_4_1_2',
                'term_of_service_4_1_3',
                'term_of_service_4_1_4',
                'term_of_service_4_1_5',
                'term_of_service_4_1_6',
                'term_of_service_4_1_7',
                'term_of_service_4_1_8',
                'term_of_service_4_1_9',
                'term_of_service_4_1_10',
                'term_of_service_4_1_11',
                'term_of_service_4_1_12',
                'term_of_service_4_1_13',
                'term_of_service_4_1_14',
                'term_of_service_4_2',
                'term_of_service_4_3',
                'term_of_service_4_4'
              ],
              dot: true
            },
            {
              title: 'term_of_service_title_5',
              content: [
                'term_of_service_5_1',
                'term_of_service_5_2',
                'term_of_service_5_3',
                'term_of_service_5_4',
                'term_of_service_5_5',
                'term_of_service_5_6',
                'term_of_service_5_7',
                'term_of_service_5_8',
                'term_of_service_5_9',
                'term_of_service_5_10',
                'term_of_service_5_11',
                'term_of_service_5_12',
                'term_of_service_5_13',
                'term_of_service_5_14',
                'term_of_service_5_15',
                'term_of_service_5_16',
                'term_of_service_5_17',
                'term_of_service_5_18',
                'term_of_service_5_19'
              ],
              dot: true
            },
            {
              title: 'term_of_service_title_6',
              content: ['term_of_service_6_1', 'term_of_service_6_2', 'term_of_service_6_3', 'term_of_service_6_4', 'term_of_service_6_5', 'term_of_service_6_6', 'term_of_service_6_7', 'term_of_service_6_8', 'term_of_service_6_9', 'term_of_service_6_10', 'term_of_service_6_11', 'term_of_service_6_12', 'term_of_service_6_13', 'term_of_service_6_14'],
              dot: true
            },
            {
              title: 'term_of_service_title_7',
              content: ['term_of_service_7_1', 'term_of_service_7_2'],
              dot: true
            },
            {
              title: 'term_of_service_title_8',
              content: ['term_of_service_8_1', 'term_of_service_8_2', 'term_of_service_8_3', 'term_of_service_8_4', 'term_of_service_8_5', 'term_of_service_8_6'],
              dot: true
            },
            {
              title: 'term_of_service_title_9',
              content: ['term_of_service_9_1', 'term_of_service_9_2', 'term_of_service_9_3', 'term_of_service_9_4', 'term_of_service_9_5', 'term_of_service_9_6', 'term_of_service_9_7', 'term_of_service_9_8', 'term_of_service_9_9', 'term_of_service_9_10', 'term_of_service_9_11', 'term_of_service_9_12', 'term_of_service_9_13', 'term_of_service_9_14', 'term_of_service_9_15'],
              dot: true
            },
            {
              title: 'term_of_service_title_10',
              content: ['term_of_service_10_1', 'term_of_service_10_2', 'term_of_service_10_3'],
              dot: true
            },
            {
              title: 'term_of_service_title_11',
              content: ['term_of_service_11_1', 'term_of_service_11_2', 'term_of_service_11_3'],
              dot: true
            },
            {
              title: 'term_of_service_title_12',
              content: ['term_of_service_12_1', 'term_of_service_12_2', 'term_of_service_12_3', 'term_of_service_12_4', 'term_of_service_12_5', 'term_of_service_12_6', 'term_of_service_12_7', 'term_of_service_12_8', 'term_of_service_12_9', 'term_of_service_12_10', 'term_of_service_12_11', 'term_of_service_12_12', 'term_of_service_12_13'],
              dot: true
            },
            {
              title: 'term_of_service_title_13',
              content: ['term_of_service_13_1', 'term_of_service_13_2', 'term_of_service_13_3', 'term_of_service_13_4'],
              dot: true
            },
            {
              title: 'term_of_service_title_14',
              content: ['term_of_service_14_1'],
              dot: true
            },
            {
              title: 'term_of_service_title_15',
              content: ['term_of_service_15_1', 'term_of_service_15_2'],
              dot: true
            },
            {
              title: 'term_of_service_title_16',
              content: ['term_of_service_16_1', 'term_of_service_16_2', 'term_of_service_16_3'],
              dot: true
            },
            {
              title: 'term_of_service_title_17',
              content: ['term_of_service_17_1', 'term_of_service_17_2', 'term_of_service_17_2_1', 'term_of_service_17_2_2', 'term_of_service_17_2_3', 'term_of_service_17_3'],
              dot: true
            },
            {
              title: 'term_of_service_title_18',
              content: ['term_of_service_18_1', 'term_of_service_18_2', 'term_of_service_18_3', 'term_of_service_18_4'],
              dot: true
            },
            {
              title: 'term_of_service_title_19',
              content: ['term_of_service_19_1', 'term_of_service_19_2', 'term_of_service_19_3'],
              dot: true
            },
            {
              title: 'term_of_service_title_20',
              content: [
                'term_of_service_20_1',
                'term_of_service_20_2',
                'term_of_service_20_3',
                'term_of_service_20_3_1',
                'term_of_service_20_3_2',
                'term_of_service_20_3_3',
                'term_of_service_20_3_4',
                'term_of_service_20_3_5',
                'term_of_service_20_3_6',
                'term_of_service_20_3_7',
                'term_of_service_20_3_8',
                'term_of_service_20_3_9',
                'term_of_service_20_3_10',
                'term_of_service_20_3_11',
                'term_of_service_20_3_12',
                'term_of_service_20_3_13',
                'term_of_service_20_3_14',
                'term_of_service_20_4',
                'term_of_service_20_5',
                'term_of_service_20_6'
              ],
              dot: true
            },
            {
              title: 'term_of_service_title_21',
              content: ['term_of_service_21_1'],
              dot: true
            },
            {
              title: 'term_of_service_title_22',
              content: ['term_of_service_22_1', 'term_of_service_22_2', 'term_of_service_22_3', 'term_of_service_22_4', 'term_of_service_22_5', 'term_of_service_22_6'],
              dot: true
            },
            {
              title: 'term_of_service_title_23',
              content: ['term_of_service_23_1'],
              dot: true
            },
            {
              title: 'term_of_service_title_24',
              content: ['term_of_service_24_1'],
              dot: true
            },
            {
              title: 'term_of_service_title_25',
              content: ['term_of_service_25_1', 'term_of_service_25_2'],
              dot: true
            },
            {
              title: 'term_of_service_title_26',
              content: ['term_of_service_26_1', 'term_of_service_26_2', 'term_of_service_26_3', 'term_of_service_26_4', 'term_of_service_26_5', 'term_of_service_26_6', 'term_of_service_26_7'],
              dot: true
            }
          ]
        },
        {
          code: 'Withdrawal',
          children: [
            {
              title: 'what_is___amount',
              content: ['the_withdrawable___balance'],
              dot: false
            },
            {
              title: 'why_is___amount',
              content: ['in_order___secure'],
              dot: false
            },
            {
              title: 'how_to___amount',
              content: ['you_can___amount'],
              dot: false
            },
            {
              title: 'why_will___reduced',
              content: ['where_may___deducted', 'where_may___deducted_0', 'where_may___deducted_1'],
              dot: false
            }
          ]
        },
        {
          code: 'Miner_Fee',
          children: [
            {
              title: 'why_does___fee',
              content: ['when_wen___mining', 'when_wen___mining_0', 'when_wen___mining_1'],
              dot: false
            },
            {
              title: 'the_role___fee',
              content: ['encourage_miners___transaction', 'encourage_miners___transaction_0'],
              dot: false
            },
            {
              title: 'how_much___fee',
              content: ['since_the___platform'],
              dot: false
            }
          ]
        },
        {
          code: 'Responsible_Gaming',
          children: [
            {
              title: 'responsible_gaming',
              content: ['wingamegame_is___form', 'wingamegame_is___form_0', 'wingamegame_is___form_1', 'wingamegame_is___form_2', 'wingamegame_is___form_3'],
              dot: false
            },
            {
              title: 'the_restricted___are',
              content: ['australia_aruba___possessions'],
              dot: false
            },
            {
              title: 'maintaining_control',
              content: [
                'gambling_should___organisations',
                'gambling_should___organisations_0',
                'gambling_should___organisations_1',
                'gambling_should___organisations_2',
                'gambling_should___organisations_3',
                'gambling_should___organisations_4',
                'gambling_should___organisations_5',
                'gambling_should___organisations_6',
                'gambling_should___organisations_7',
                'gambling_should___organisations_8',
                'gambling_should___organisations_9',
                'gambling_should___organisations_10',
                'gambling_should___organisations_11',
                'gambling_should___organisations_12',
                'gambling_should___organisations_13',
                'gambling_should___organisations_14',
                'gambling_should___organisations_15',
                'gambling_should___organisations_16',
                'gambling_should___organisations_17',
                'gambling_should___organisations_18',
                'gambling_should___organisations_19',
                'gambling_should___organisations_20',
                'gambling_should___organisations_21'
              ],
              dot: false
            },
            {
              title: 'self_exclusion',
              content: ['should_you___considered', 'should_you___considered_0', 'should_you___considered_1', 'should_you___considered_2'],
              dot: false
            },
            {
              title: 'gambling_counselling___organisations',
              content: ['gamcare_the___org', 'gamcare_the___org_0', 'gamcare_the___org_1', 'gamcare_the___org_2', 'gamcare_the___org_3', 'gamcare_the___org_4', 'gamcare_the___org_5'],
              dot: false
            },
            {
              title: 'filtering_systems',
              content: ['filtering_solutions___com', 'filtering_solutions___com_0', 'filtering_solutions___com_1'],
              dot: false
            }
          ]
        }
      ]
    };
  },
  created() {},
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style scoped>
.phone-top-title {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  line-height: 2rem;
  background-color: var(--phone_help-top-title-bg-color);
}

::v-deep .el-select__wrapper {
  background-color: #16202c;
  box-shadow: 0 0 0 1px #0d101e inset;
}

::v-deep .el-select__placeholder {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}
</style>
