<script setup>
import NewDeposit from '@/components/wallet/NewDeposit';
import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<template>
  <div class="bg-[#191F33] p-[15px]">
    <NewDeposit :tabIndex="route.query.tab" :defSymbol="route.query.symbol" />
  </div>
</template>
