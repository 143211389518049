import api from '../api';
import httpManager from '../httpManager';

// 获取钱包余额
export const userWalletInfosService = (params) => {
  return httpManager({
    url: api.gameGetSimpleTokensInfo,
    method: 'post',
    data: params || {}
  });
};

// 获取重置地址
export const walletDepositInfoService = (params) => {
  return httpManager({
    url: api.gameGetTokensInfo,
    method: 'post',
    data: params
  });
};

// 获取单个充值地址（新接口）
export const walletGenerateNewAddressService = (params) => {
  return httpManager({
    url: api.walletGenerateNewAddress,
    method: 'post',
    data: params
  });
};

// 划转
export const walletTransferService = (params) => {
  return httpManager({
    url: api.walletTransfer,
    method: 'post',
    data: params
  });
};

// 提现
export const walletWithdrawService = (params) => {
  return httpManager({
    url: api.walletWithdrawRequest,
    method: 'post',
    data: params
  });
};
// 提现
export const cryptoWalletWithdrawRequest = (params) => {
  return httpManager({
    url: api.cryptoWalletWithdrawRequest,
    method: 'post',
    data: params
  });
};

// 充值记录
export const walletDepositRecordService = (params) => {
  return httpManager({
    url: api.walletGetDepositRecord,
    method: 'get',
    params
  });
};

// 提现记录
export const walletWithdrawRecordService = (params) => {
  return httpManager({
    url: api.walletGetWithdrawRecord,
    method: 'get',
    params
  });
};

// 法币充值获取选择金额列表
export const getFiatPreDeposit = (params) => {
  return httpManager({
    url: api.getFiatPreDeposit,
    method: 'get',
    params
  });
};

// 法币充值
export const fiat_deposit = (params) => {
  return httpManager({
    url: api.fiat_deposit,
    method: 'post',
    data: params
  });
};

// 法币充值
export const add_withdraw_password = (params) => {
  return httpManager({
    url: api.add_withdraw_password,
    method: 'post',
    data: params
  });
};
// 提现修改密码，发送邮箱验证码
export const send_withdraw_password_code = (params) => {
  return httpManager({
    url: api.send_withdraw_password_code,
    method: 'post',
    data: params
  });
};
// 修改提现密码
export const reset_withdraw_password = (params) => {
  return httpManager({
    url: api.reset_withdraw_password,
    method: 'post',
    data: params
  });
};

// 法币充值获取选择金额列表
export const fiat_pre_withdraw = (params) => {
  return httpManager({
    url: api.fiat_pre_withdraw,
    method: 'get',
    params
  });
};
// 添加提现银行卡
export const add_bank_card = (params) => {
  return httpManager({
    url: api.add_bank_card,
    method: 'post',
    data: params
  });
};

// 法币充值获取选择金额列表
export const fiat_withdraw = (params) => {
  return httpManager({
    url: api.fiat_withdraw,
    method: 'post',
    data: params
  });
};
// 法币提现列表
export const getFiatWithdrawList = (params) => {
  return httpManager({
    url: api.getFiatWithdrawList,
    method: 'get',
    params
  });
};
// 获取货币类型
export const getTokens = (params) => {
  return httpManager({
    url: api.getTokens,
    method: 'get',
    params
  });
};

// 墨西哥提现，添加银行卡
export const add_bank_account = (params) => {
  return httpManager({
    url: api.add_bank_account,
    method: 'post',
    data: params
  });
};
