<script setup>
// 导入
import { ref, computed, watch } from 'vue';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import useWalletStore from '@/store/wallet';
import { add_bank_card } from '@/assets/network/service/wallet';
import ButtonItem from '@/components/wallet/module/ButtonItem';
// 传参
// 属性
const { t } = useI18n();
const walletStore = useWalletStore();
const typeList = ['CPF', 'EMAIL'];
const info_BRL = ref({
  name: walletStore.fiatWithdrawOpt[0]?.person_name || '',
  type: 'CPF',
  account: '',
  id: walletStore.fiatWithdrawOpt[0]?.person_id || ''
});
// 计算属性
watch([() => walletStore.fiatWithdrawOpt], () => {
  walletStore.getFiatWithdrawOpt();
});
const isDisabled = computed(() => {
  let res = false;
  if (info_BRL.value.name) {
    res = true;
  } else {
    res = false;
  }
  if (info_BRL.value.id.length != 11) {
    res = true;
  } else {
    res = false;
  }
  return res;
});
const isInput = computed(() => {
  if (walletStore.fiatWithdrawOpt.length > 0) {
    return true;
  } else {
    return false;
  }
});
// 生命周期
// 方法
const clickConfirm = async () => {
  if (isDisabled.value) {
    return;
  }
  const par = {
    account_type: info_BRL.value.type,
    person_name: info_BRL.value.name,
    person_id: info_BRL.value.id,
    account_id: info_BRL.value.account
  };
  const res = await add_bank_card(par);
  if (res.data.code == 200) {
    toast(t('success'), { autoClose: 2000, type: 'success' });
    walletStore.showDialogAddPix = false
    walletStore.getFiatWithdrawOpt();
  } else {
    toast(res.data.msg, { autoClose: 2000, type: 'error' });
  }
};
</script>

<template>
  <el-dialog v-model="walletStore.showDialogAddPix" width="420" align-center :close-on-click-modal="false" class="bg-[#121829] text-left">
    <template #header>
      <div class="text-[#fff] text-[14px] font-[700]">{{ t('add_pix').toUpperCase() }}</div>
    </template>
    <div class="relative text-[12px] text-[#5B7BAB] text-left font-[700]">
      <div class="mb-[15px]">
        <div class="mb-[5px]">{{ t('name').toUpperCase() }}</div>
        <el-input v-model="info_BRL.name" class="my-el-css" size="large" :disabled="isInput" clearable :placeholder="t('please_enter_the_name')"> </el-input>
      </div>
      <div class="mb-[15px]">
        <div class="mb-[5px]">PIX</div>
        <el-select v-model="info_BRL.type" class="my-el-css" size="large" :teleported="false">
          <el-option v-for="(item, index) in typeList" :key="index" :label="`PIX-${item}`" :value="item" />
        </el-select>
      </div>
      <div v-if="info_BRL.type != 'CPF'" class="mb-[15px]">
        <div class="mb-[5px]">{{ `PIX ${t('account').toUpperCase()}` }}</div>
        <el-input v-model="info_BRL.account" class="my-el-css" size="large" clearable :placeholder="t('please_enter_your_pix_account')"> </el-input>
      </div>
      <div class="mb-[15px]">
        <div class="mb-[5px]">{{ `CPF ${t('number').toUpperCase()}` }}</div>
        <el-input v-model="info_BRL.id" class="my-el-css" size="large" :disabled="isInput" clearable :placeholder="t('please_enter_the_11digit')"> </el-input>
      </div>
      <div class="w-full">
        <ButtonItem :btnText="t('confirm')" :disabled="isDisabled" @click="clickConfirm" />
      </div>
    </div>
  </el-dialog>
</template>

<style scoped></style>
