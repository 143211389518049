<script setup>
import { ref, defineProps, defineEmits, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const props = defineProps({
  show: Boolean,
  config: Object
});
const emit = defineEmits(['close']);
const progress = ref(100);
let countdownInterval;
function startCountdown(duration) {
  progress.value = 100;
  let time = duration;
  countdownInterval = setInterval(() => {
    time--;
    progress.value = (time / duration) * 100;
    if (time < 0) {
      closeSelf();
    }
  }, 1000);
}

watchEffect(() => {
  if (props.show) {
    if (props.config) {
      startCountdown(props.config.display_time);
    }
  }
});
function closeSelf() {
  clearInterval(countdownInterval);
  emit('close');
}

function clickBtn() {
  closeSelf();
  if (props.config.link_type == 'External') {
    if (props.config.link) {
      if (props.config.link.startsWith('https://') || props.config.link.startsWith('http://')) {
        window.open(props.config.link, '_blank');
      } else {
        window.open('https://' + props.config.link, '_blank');
      }
    }
  } else {
    router.push(props.config.link);
  }
}
</script>

<template>
  <div v-if="props.show && props.config" class="tg-entry-container">
    <div class="title">
      <div class="title-text">{{ props.config.title }}</div>
      <button class="title-close" @click="closeSelf">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
          <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
        </svg>
      </button>
    </div>
    <div v-if="props.config.image_switch" class="content">
      <img class="content-bg" :src="props.config.image_url" />
    </div>
    <div class="tip">
      {{ props.config.desc }}
    </div>
    <div v-if="props.config.button_switch" class="bottom">
      <button class="bottom-btn" @click="clickBtn">{{ props.config.button_text }}</button>
    </div>
    <div class="bar" :style="{ width: progress + '%' }"></div>
  </div>
</template>

<style scoped>
@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
.tg-entry-container {
  position: fixed;
  top: 60px;
  right: 0;
  z-index: 999;
  width: 480px;
  background-color: var(--my-dialog-main-bg-color);
  border-radius: 0.5rem;
  margin: 1rem;
  animation: slide-in 0.5s ease-out;

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 0.5rem;
    .title-text {
      color: var(--dialog-title-color);
      font-size: 1rem;
      font-weight: 600;
    }
    .title-close {
      background-color: transparent;
      cursor: pointer;
      border: none;
    }
    .title-close:hover {
      filter: brightness(0) invert(1);
    }
  }

  .content {
    position: relative;
    padding: 0 1rem;
    .content-bg {
      display: block;
      width: 100%;
      height: 160px;
    }
  }

  .tip {
    color: var(--color-8ea3c3);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    text-align: left;
    padding: 0.5rem 1rem;
  }

  .bottom {
    position: relative;
    padding: 0 1rem 1rem;
    .bottom-btn {
      background-color: var(--bg-blue-color);
      border: none;
      padding: 0;
      color: var(--default-btn-color);
      font-size: 1.125rem;
      font-weight: 700;
      border-radius: 0.5rem;
      width: 100%;
      height: 3rem;
      cursor: pointer;
      transition: all 0.25s;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bottom-btn:hover {
      opacity: 0.8;
    }
  }

  .bar {
    border-radius: 0.5rem;
    position: relative;
    width: 100%;
    height: 6px;
    background: linear-gradient(90deg, #00d66f 0%, #3dbcd8 49%, #1184fa 100%);
    transition: width 1s linear;
  }
}
@media (max-width: 780px) {
  .tg-entry-container {
    width: 80%;
    border-radius: 8px;

    .title {
      padding: 8px;
      .title-text {
        font-size: 14px;
      }
      .title-close {
        background-color: transparent;
        cursor: pointer;
        border: none;
      }
      .title-close:hover {
        filter: brightness(0) invert(1);
      }
    }

    .content {
      position: relative;
      padding: 0 8px;
      .content-bg {
        display: block;
        width: 100%;
        height: 90px;
      }
    }

    .tip {
      font-size: 12px;
      padding: 8px;
    }

    .bottom {
      padding: 0 8px 8px;
      .bottom-btn {
        font-size: 14px;
        height: 28px;
      }
    }

    .bar {
      border-radius: 8px;
    }
  }
}
</style>
