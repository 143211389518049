<script setup>
// 导入
import { defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
// 传参
const props = defineProps({
  tabIndex: {
    type: String,
    default: 'Crypto'
  }
});
const emit = defineEmits(['change']);
// 属性
const { t } = useI18n();
// 计算属性
// 生命周期
// 方法
const clickTab = (type) => {
  emit('change', type);
};
</script>

<template>
  <div class="flex bg-[#0D101E] rounded-[4px] text-[14px] font-[700] px-[3px] py-[4px] text-center">
    <div :class="props.tabIndex == 'Crypto' ? 'tab-selected' : 'tab-unselected'" class="cursor-pointer rounded-[4px] w-1/2 py-[12px]" @click="clickTab('Crypto')">
      {{ t('Crypto') }}
    </div>
    <div :class="props.tabIndex == 'Fiat' ? 'tab-selected' : 'tab-unselected'" class="cursor-pointer rounded-[4px] w-1/2 py-[12px]" @click="clickTab('Fiat')">
      {{ t('Fiat') }}
    </div>
  </div>
</template>

<style scoped>
.tab-selected {
  background-color: #222942;
  color: #ffffff;
  text-shadow:
    0px 0px 8px #1184fa,
    0px 0px 12px #1184fa;
}
.tab-unselected {
  background-color: transparent;
  color: #5b7bab;
}
</style>
