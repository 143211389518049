<template>
  <div class="">
    <wgTable :title="tableState.title" :request="getBetsList" :search="tableState.searchConfig" :columns="tableState.columns" :initParams="tableState.initParams" :pagination="tableState.paginationConfig">
      <template #gameName="{ row }">
        <img :src="slotsImg1" v-if="row.game_type == 'CasinoSlot'" />
        <img :src="pokerImg1" v-if="row.game_type == 'CasinoLive' || row.game_type == 'Live'" />
        {{ row.game_name }}
      </template>
      <template #time="{ row }">
        {{ formatDate(row.bet_time) }}
      </template>
      <template #betAmount="{ row }">
        {{ formatNumberWithCommas(row.bet_amount) }}
        <img :src="getSymbolIconUrl(row.symbol)" class="w-[1rem] h-[1rem]" />
      </template>
      <template #multiplier="{ row }"> {{ row.multiplier }}x </template>
      <template #profit="{ row }">
        {{ formatNumberWithCommas(row.profit) }}
        <img :src="getSymbolIconUrl(row.symbol)" class="w-[1rem] h-[1rem]" />
      </template>
      <template #phoneGameName="{ row }">
        <div class="flex flex-col text-[0.65rem]">
          <div>
            {{ row.game_name }}
          </div>
          <div>{{ formatDate(row.bet_time) }}</div>
        </div>
      </template>
      <template #phonePayout="{ row }">
        <div class="flex flex-col">
          <div>
            {{ formatNumberWithCommas(row.profit) }}
            <img :src="getSymbolIconUrl(row.symbol)" class="w-[1rem] h-[1rem]" />
          </div>
          <div>{{ formatNumberWithCommas(row.bet_amount) }} <img :src="getSymbolIconUrl(row.symbol)" class="w-[1rem] h-[1rem]" /></div>
        </div>
      </template>
      <template #phoneGameNameHeader>
        <div class="flex flex-col">
          <span>{{ t('pay_out') }} /</span>
          <span>{{ t('bet_amount') }}</span>
        </div>
      </template>
    </wgTable>
  </div>
</template>
<script setup>
import slotsImg1 from '../../assets/images/home/btn_qh_1_1.svg';
import pokerImg1 from '../../assets/images/home/btn_9_2.svg';
import { useI18n } from 'vue-i18n';
import { getTokens } from '@/assets/network/service/wallet.js';
import { reactive, onMounted, ref } from 'vue';
import wgTable from '@/components/common/wgTable/wgTable.vue';
import { getGameHistoryService } from '@/assets/network/service/game';
import { getCookieUserId, formatNumberWithCommas, isMobile, formatDate } from '@/assets/tools/CommonTool';

const { t } = useI18n();
const symbolList = ref([]);
const tableState = reactive({
  title: t('my_beats'),
  initParams: {
    flag: false,
    user_id: Number(getCookieUserId()),
    date_type: 'days'
  },
  columns: isMobile()
    ? [
        {
          label: t('game'),
          tdSlot: 'phoneGameName',
          width: 140
        },
        {
          label: t('multiplier'),
          tdSlot: 'multiplier'
        },
        {
          labelSlot: 'phoneGameNameHeader',
          label: `${t('pay_out')}\n${t('bet_amount')}`,
          tdSlot: 'phonePayout',
          width: 130
        }
      ]
    : [
        {
          label: t('game'),
          tdSlot: 'gameName'
        },
        { label: t('bet_id'), prop: 'game_cycle_uid', copy: true },
        { label: t('time'), tdSlot: 'time' },
        { label: t('bet_amount'), tdSlot: 'betAmount' },
        {
          label: t('multiplier'),
          tdSlot: 'multiplier'
        },
        { label: t('pay_out'), tdSlot: 'profit' }
      ],
  // 搜索配置
  searchConfig: {
    fields: [
      {
        name: 'game_type',
        type: 'select',
        defaultValue: '',
        width: '5rem',
        options: [
          {
            name: t('All'),
            value: ''
          },
          {
            name: 'slots',
            value: 'slots'
          }
        ]
      },
      {
        name: 'symbol',
        type: 'select',
        defaultValue: '',
        width: '8rem',
        options: []
      },
      {
        name: 'date_time',
        type: 'select',
        defaultValue: '60',
        width: isMobile() ? '8.75rem' : '9.37rem',
        options: [
          {
            name: t('All'),
            value: ''
          },
          {
            name: t('past_24_hours'),
            value: '1'
          },
          {
            name: t('past_30_days'),
            value: '30'
          },
          {
            name: t('past_60_days'),
            value: '60'
          },
          {
            name: t('past_70_days'),
            value: '70'
          }
        ]
      }
    ]
  },
  // 分页配置
  paginationConfig: {
    layout: isMobile() ? 'prev,slot,next' : 'slot,->,prev,pager,next',
    pageSize: 10, // 每页条数
    pageSizes: [5, 10, 20, 50]
  }
});
// 请求函数
const getBetsList = async (params) => {
  // params是从组件接收的，包含分页和搜索字段。
  try {
    const res = await getGameHistoryService(params);
    // 必须要返回一个对象，包含data数组和total总数
    return {
      data: res.data.data,
      total: res.data.total
    };
  } catch (e) {
    return {
      data: [],
      total: 0
    };
  }
};

const getSymbolIconUrl = (symbol) => {
  if (symbolList.value.length === 0) return '';

  for (let i in symbolList.value) {
    if (symbolList.value[i].symbol == symbol) {
      return symbolList.value[i].icon_url;
    }
  }
};

onMounted(async () => {
  const tokensData = await getTokens();
  symbolList.value = tokensData.data.data;
  const symbolOptions = symbolList.value.map((item) => {
    return {
      name: item.symbol,
      value: item.symbol
    };
  });
  tableState.searchConfig.fields.find((field) => field.name === 'symbol').options = [
    {
      name: t('all_assets'),
      value: ''
    },
    ...symbolOptions
  ];
});
</script>
